import React from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useFirebase } from './FirebaseProvider';
// import { useDocument } from 'react-firebase-hooks/firestore';
// import AppLoading from './AppLoading';

function AdminRoute({ component: Component, ...restProps }) {
    const { role } = useFirebase();
    const matchTransaksi = useRouteMatch('/transaksi')
    const matchHome = useRouteMatch({ path: "/", exact: true })
    // console.log(matchHome)
    return <Route
        {...restProps}
        render={props => {


            if (role && role.data()) {
                const akses = role.data().akses;
                if (akses === "admin") {
                    return <Component {...props} />
                } else if (akses === "cs") {
                    if (matchHome)
                        return <Redirect to={{ pathname: "/transaksi" }} />

                    if (matchTransaksi)
                        return <Component {...props} />
                }

            }


            return <Redirect to={{
                pathname: "/restricted",
            }} />
        }}
    />
}

export default AdminRoute;