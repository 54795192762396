import React, { useState } from 'react';

// material-ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// Utils
import isEmail from 'validator/lib/isEmail';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFirebase } from '../../../../components/FirebaseProvider';
import PropTypes from 'prop-types';
import useStyles from './styles';

function AddDialog({ history, open, handleClose }) {

    const { firestore } = useFirebase();

    const classes = useStyles();

    const [form, setForm] = useState({
        email: '',
        password: '',
        role: 'admin'
    })

    const [error, setError] = useState({
        email: '',
        password: '',
        role: ''
    })

    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })


    }

    const validate = async () => {
        const newError = { ...error };
        const { email, password } = form;


        if (!email) {
            newError.email = 'Email wajib di isi';

        } else if (!isEmail(email)) {
            newError.email = 'Email tidak valid';
        } else {
            const querySanpshot = await firestore.collection('admin').where('email', '==', email).get();

            if (!querySanpshot.empty) {
                newError.email = 'Email sudah terdaftar sebagai admin';
            }
        }

        if (!password) {
            newError.password = 'Password wajib di isi';
        }


        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const adminCol = firestore.collection(`admin`);

                const newAdmin = await adminCol.add(form);

                await firestore.doc(`roles/${newAdmin.id}`).set({
                    akses: form.role
                })
                enqueueSnackbar('Admin berhasil ditambahkan', { variant: "success" })
                //await auth.signInWithEmailAndPassword(form.email, form.password);
                //history.push(`/thankyou`)
                handleClose()
            } catch (e) {
                const newError = {};

                newError.email = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }

    return <Dialog
        disableBackdropClick={isSubmitting}
        disableEscapeKeyDown={isSubmitting}
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>Buat Admin Baru</DialogTitle>
        <DialogContent dividers>
            <form id="create-admin-form" onSubmit={handleSubmit}>
                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="email"
                    name="email"
                    label="Admin Email"
                    fullWidth
                    autoComplete="off"
                    value={form.email}
                    onChange={handleChange}
                    helperText={error.email}
                    error={error.email ? true : false}
                />

                <TextField
                    disabled={isSubmitting}
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    fullWidth
                    value={form.password}
                    onChange={handleChange}
                    helperText={error.password}
                    error={error.password ? true : false}
                />

                <FormControl style={{ width: '100%' }}>
                    <InputLabel>Role</InputLabel>
                    <Select
                        className={classes.marginTextfield}
                        labelId="demo-simple-select-label"
                        id="role"
                        name="role"
                        fullWidth
                        value={form.role}
                        onChange={handleChange}
                        disabled={isSubmitting}
                    >
                        <MenuItem value={'admin'}>Administrator</MenuItem>
                        <MenuItem value={'cs'}>CS</MenuItem>
                    </Select>
                </FormControl>

            </form>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={isSubmitting}
                onClick={handleClose}
            >Batal</Button>
            <Button
                form="create-admin-form"
                disabled={isSubmitting}
                type="submit"
                color="primary">Simpan</Button>
        </DialogActions>
    </Dialog>

}

AddDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withRouter(AddDialog);
