import React, { useState, useEffect } from 'react';

// material-ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

// utils
import { useSnackbar } from 'notistack';
import { firestore } from '../../../components/FirebaseProvider';
import PropTypes from 'prop-types';



function AddDialogC({ open, handleClose, mode, data }) {

    const [form, setForm] = useState({
        title: '',
    })

    const [error, setError] = useState({
        title: '',
    })

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (mode === "Edit") {
            setForm(data);
        } else {
            setForm({ id: '', title: '' })
        }
    }, [mode, data])

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }


    const validate = async () => {
        const newError = { ...error };
        const { title } = form;


        if (!title) {
            newError.title = 'Masukkan Judul';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const catCol = firestore.collection(`kategori_soal`);
                if (mode === "Tambah") {
                    await catCol.add({
                        title: form.title,
                        createdAt: Date.now(),
                        updatedAt: Date.now()
                    });
                    enqueueSnackbar('Kategori Berhasil Ditambahkan', { variant: "success" })
                }
                else if (mode === "Edit") {
                    await catCol.doc(form.uid).set({
                        title: form.title,
                        updatedAt: Date.now()
                    }, { merge: true });
                    enqueueSnackbar('Kategori Berhasil Diperbarui', { variant: "success" })
                }
                handleClose();

            } catch (e) {
                console.log(e.message)
            }

            setSubmitting(false)
        }
    }


    let btnText = "Perbarui"

    if (mode === "Tambah") {
        btnText = "Buat"
    }

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{mode} Kategori</DialogTitle>
            <DialogContent dividers>
                <form id="create-admin-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting}
                                autoComplete="off"
                                id="title"
                                name="title"
                                label="Judul Kategori"
                                fullWidth
                                variant="outlined"
                                value={form.title}
                                onChange={handleChange}
                                helperText={error.title}
                                error={error.title ? true : false}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    form="create-admin-form"
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                >
                    {btnText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddDialogC.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddDialogC;
