import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import firebaseConfig from "../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import AppLoading from "./AppLoading";

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.app().functions("asia-southeast2");
export const Arr = firebase.firestore.FieldValue;
export const FieldValue = firebase.firestore.FieldValue;
export const Timestamp = firebase.firestore.Timestamp;
const FirebaseContext = React.createContext();
export function useFirebase() {
  return useContext(FirebaseContext);
}

function FirebaseProvider(props) {
  const [user, loading, error] = useAuthState(auth);
  const roledoc = firestore.doc(`roles/${user ? user.uid : "none"}`);
  const [role, loadingrole] = useDocument(roledoc);
  const [isCacheEnabled, setCacheEnabled] = useState(false);

  //
  const [form, setForm] = useState({
    kategori_soal: {
      id: "",
      nama: "",
    },
    tryout: {
      id: "",
      nama: "",
    },
  });
  //

  useEffect(() => {
    const enablePersistence = async () => {
      try {
        //await firebase.firestore().enablePersistence()
        setCacheEnabled(true);
        console.log("cache enabled");
      } catch (err) {
        console.log(err.code);
      }
    };
    enablePersistence();
  }, []);

  if (!isCacheEnabled || loading || loadingrole) {
    return <AppLoading />;
  }
  return (
    <FirebaseContext.Provider
      value={{
        auth,
        Arr,
        firestore,
        functions,
        user,
        role,
        loading,
        error,
        form,
        setForm,
        FieldValue: firebase.firestore.FieldValue,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
