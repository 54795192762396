import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useFirebase } from '../../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';

// utils
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export const tipeOptions = ['offline', 'online'];

export const lokasiOptions = [
    'Bandung Kota',
    'Jakarta Selatan',
    'Surabaya',
    'Yogyakarta',
    'Jakarta Timur',
    'Bekasi Kota',
    'Semarang',
    'Tangerang Kota',
    'Tangerang Selatan',
    'Surakarta',
    'Bogor',
    'Malang',
    'Depok',
    'Medan',
    'Palembang',
    'Bandar Lampung',
    'Banyumas Purwokerto',
    'Tegal',
    'Madiun',
    'Samarinda',
    'Cirebon',
];
function AddDialogN({ open, handleClose }) {
    const history = useHistory();

    const { firestore } = useFirebase();

    const queryKategori = firestore.doc('news/kategori');
    const [kategoriSnapshot, loading] = useDocument(queryKategori);

    const [form, setForm] = useState({
        nama: '',
        tipe: 'offline',
        lokasi_offline: '',
        lokasi_online: ''
    })

    const [error, setError] = useState({
        title: ''
    })

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const [kategoriOptions, setKategoriOptions] = useState([]);

    useEffect(() => {
        if (kategoriSnapshot) {
            setKategoriOptions(kategoriSnapshot.data() && kategoriSnapshot.data().items && kategoriSnapshot.data().items.map(item => ({ id: item.id, title: item.title })));
        }
    }, [kategoriSnapshot])

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    const handleAutocompleteChange = name => (e, v) => {

        handleChange({ target: { value: v, name } })
    }

    const validate = async () => {
        const newError = { ...error };
        const { nama, tipe } = form;


        if (!nama) {
            newError.nama = 'Masukkan nama event.';
        }

        if (!tipe) {
            newError.tipe = 'Pilih tipe event.'
        }

        if (tipe === 'offline' && !form.lokasi_offline) {
            newError.lokasi_online = 'Lokasi wajib diisi.'
        } else if (tipe === 'online' && !form.lokasi_online) {
            newError.lokasi_offline = 'Lokasi wajib diisi.'
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const newsCol = firestore.collection(`events`);
                const newNews = await newsCol.add({
                    ...form,
                    created_at: Date.now(),
                    updated_at: Date.now(),
                    status: 'draft',
                });

                enqueueSnackbar('Event Berhasil Dibuat', { variant: "success" })
                history.push(`/event/edit/${newNews.id}`)
            } catch (e) {
                const newError = {};

                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }

    //console.log(form)

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Buat Event Baru</DialogTitle>
            <DialogContent dividers>
                <form id="create-admin-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting}
                                autoComplete="off"
                                id="nama"
                                name="nama"
                                label="Nama Event"
                                fullWidth
                                variant="outlined"
                                value={form.nama}
                                onChange={handleChange}
                                helperText={error.nama}
                                error={error.nama ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="kategoriOptions"
                                options={tipeOptions}
                                getOptionLabel={option => option}
                                value={form.tipe}
                                onChange={handleAutocompleteChange('tipe')}
                                name="tipe"
                                debug={true}
                                disableClearable
                                loading={loading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Tipe Event"
                                        variant="outlined"
                                        fullWidth
                                        helperText={error.tipe}
                                        error={error.tipe ? true : false}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {form.tipe === 'offline' && <Grid item xs={12}>
                            <Autocomplete
                                id="lokasiOptions"
                                options={lokasiOptions}
                                getOptionLabel={option => option}
                                value={form.lokasi_offline}
                                onChange={handleAutocompleteChange(`lokasi_offline`)}
                                name="lokasi_offline"
                                debug={true}
                                disableClearable
                                loading={loading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Lokasi Event"
                                        variant="outlined"
                                        fullWidth
                                        helperText={error.lokasi_offline}
                                        error={error.lokasi_offline ? true : false}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off',
                                        }}
                                    />
                                )}
                            />
                        </Grid>}
                        {
                            form.tipe === 'online' && <Grid item xs={12}>
                                <TextField
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    id="lokasi_online"
                                    name="lokasi_online"
                                    label="URL Event"
                                    fullWidth
                                    variant="outlined"
                                    value={form.lokasi_online}
                                    onChange={handleChange}
                                    helperText={error.lokasi_online}
                                    error={error.lokasi_online ? true : false}
                                />
                            </Grid>
                        }
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    form="create-admin-form"
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                >
                    Buat
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddDialogN.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddDialogN;
