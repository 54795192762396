// React
import React from 'react';

import { useHistory } from 'react-router-dom';

// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

// Styles
import useStyles from './styles/index';

function NotFound() {
    const classes = useStyles();
    const history = useHistory();
    return(
        <Container maxWidth="sm">
                <div className={classes.content}>
                    <Typography className={classes.text1}>Anda Nyasar !</Typography>
                    <Typography className={classes.text3}>Mungkin permintaan anda salah.<br/>Cek permintaan anda, atau tekan tombol dibawah untuk kembali ke home</Typography>

                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        // onClick={()=>{window.history.back()}}
                        onClick={() => history.push("/home")}
                    >
                        Kembali ke home
                    </Button>
                </div>
        </Container>
    )
}

export default NotFound;