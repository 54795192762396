import React from 'react';

// MUI
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

// Utils
import { Switch, Route, Redirect } from 'react-router-dom';

// Component
import Admin from './admin';
import BK from './bk';
import Mitra from './mitra';
import MitraDetail from './mitra/detail';

function Routing() {

    return (
        <Switch>
            <Route path="/akun/mitra/detail/:mitra_id" component={MitraDetail} />
            <Route component={Pengaturan} />
        </Switch>
    )
}
function Pengaturan(props) {

    const { location, history } = props;
    const handleChangeTab = (event, value) => {

        history.push(value);
    }

    return (
        <Paper square style={{ marginBottom: 50 }}>
            <Tabs
                centered
                variant="fullWidth"
                value={location.pathname}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeTab}
            >
                <Tab label="Admin" value="/akun/admin" />
                {/* <Tab label="Guru BK" value="/akun/bk" /> */}
                <Tab label="Mitra" value="/akun/mitra" />
            </Tabs>
            <Divider />
            <div>
                <Switch>
                    <Route path="/akun/admin" component={Admin} />
                    {/* <Route path="/akun/bk" component={BK} /> */}
                    <Route path="/akun/mitra" component={Mitra} />
                    <Redirect to="/akun/admin" />
                </Switch>
            </div>
        </Paper>
    )
}

export default Routing;