import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// utils
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { firestore, Arr } from '../../../components/FirebaseProvider';
import useStyles from './styles';
import RpFormat, {Numberformat} from '../../../components/NumberFormat';

function AddDialog({ dialog: { mode, open, data }, handleClose}) {

    const classes = useStyles();

    const [form, setForm] = useState({
        nilai_koin: '',
        nilai_rupiah: '',
        status: false

    })

    const [error, setError] = useState({
        nilai_koin: '',
        nilai_rupiah: '',
        status: ''
    })
    useEffect(() => {
        const defaultData = {
            nilai_koin: '',
            nilai_rupiah: '',
            status: false
        }

        const defaultError = {
            nilai_koin: '',
            nilai_rupiah: '',
            status: ''
        }

        if (mode === 'Tambah') {
            setForm(defaultData)
            setError(defaultError)
        } else if (mode === 'Edit') {
            setForm(data)
            setError(defaultError)
        }
    }, [data, mode])



    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(form => ({
            ...form,
            [name]: value
        }))

        setError(error => ({
            ...error,
            [name]: ''
        }))
    }

    const validate = async () => {
        const newError = { ...error };
        const { nilai_koin, nilai_rupiah} = form;

        if (!nilai_koin) {
            newError.nilai_koin = 'Nilai Koin Wajib Diisi';
        }

        if (!nilai_rupiah) {
            newError.nilai_rupiah = 'Nilai Rupiah Wajib Diisi';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();
        console.log(findError)


        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            const promoCol = firestore.collection(`promo_koin`);
            try {
                if (mode === 'Tambah') {
                    await promoCol.add({
                        nilai_koin: form.nilai_koin,
                        nilai_rupiah: form.nilai_rupiah,
                        status: form.status,
                        dibuat_pada: Arr.serverTimestamp()
                    });
                    handleClose()
                    enqueueSnackbar('Promo Koin berhasil ditambahkan', { variant: "success" })
                }else if (mode === 'Edit') {
                    const { uid, tableData, ...restForm } = form;
                    await promoCol.doc(uid).update({
                        ...restForm, diperbarui_pada: Arr.serverTimestamp()
                    })
                    handleClose()
                    enqueueSnackbar('Promo Koin Berhasil Diperbarui', { variant: "success" })
                }
            } catch (e) {
                const newError = {};

                newError.nilai_koin = e.message;

                setError(newError);
            }
            setSubmitting(false)
        }
    }

    return <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick={isSubmitting}
        disableEscapeKeyDown={isSubmitting}
        open={open}
    >
        <DialogTitle>{mode} Promo Koin</DialogTitle>
        <DialogContent dividers>
            <form id="create-admin-form" onSubmit={handleSubmit}>
                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="nilai_koin"
                    name="nilai_koin"
                    label="Nilai Koin"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputComponent: Numberformat
                    }}
                    fullWidth
                    autoComplete="off"
                    value={form.nilai_koin}
                    onChange={handleChange}
                    helperText={error.nilai_koin}
                    error={error.nilai_koin ? true : false}
                />
                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="nilai_rupiah"
                    name="nilai_rupiah"
                    label="Nilai Rupiah"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputComponent: RpFormat,
                    }}
                    fullWidth
                    autoComplete="off"
                    value={form.nilai_rupiah}
                    onChange={handleChange}
                    helperText={error.nilai_rupiah}
                    error={error.nilai_rupiah ? true : false}
                />
                <FormControl style={{ width: '100%' }}>
                    <InputLabel>Status Promo</InputLabel>
                    <Select
                        className={classes.marginTextfield}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="status"
                        fullWidth
                        value={form.status}
                        onChange={handleChange}
                        disabled={isSubmitting}
                    >
                        <MenuItem value={true}>Aktif</MenuItem>
                        <MenuItem value={false}>Tidak Aktif</MenuItem>
                    </Select>
                </FormControl>
            </form>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={isSubmitting}
                onClick={handleClose}
            >Batal</Button>
            <Button
                form="create-admin-form"
                disabled={isSubmitting}
                type="submit"
                color="primary">Simpan</Button>
        </DialogActions>
    </Dialog >

}

AddDialog.propTypes = {
    dialog: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withRouter(AddDialog);
