// React
import React, { useState, useEffect } from 'react';

//Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// Styles
import useStyles from './styles';

// MUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { DateTimePicker } from "@material-ui/pickers";
//Component
import UploadFoto from './uploadFoto';

//Utils
import { Timestamp, useFirebase } from '../../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';
import AppPageLoading from '../../../components/AppPageLoading';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { lokasiOptions, tipeOptions } from './addEvent';
// import {baseURL} from '../../images/s3';

function NewsDetail(props) {
    const classes = useStyles();

    const params = useParams();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const { firestore, functions } = useFirebase();
    const docRef = firestore.doc(`events/${params.eventId}`)
    const [snapshot, loading] = useDocument(docRef);

    // const queryKategori = firestore.doc('news/kategori');
    // const [kategoriSnapshot, kategoriLoading] = useDocument(queryKategori);

    const [form, setForm] = useState({

    });

    const [error, setError] = useState({})

    const [isSubmitting, setSubmitting] = useState(false);

    const [isChange, setChange] = useState(false);

    // const [kategoriOptions, setKategoriOptions] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const handleAutocompleteChange = name => (e, v) => {
        handleChange({ target: { value: v, name } })
    }


    useEffect(() => {
        if (snapshot) {

            const data = snapshot.data();

            setForm(currentForm => ({
                ...currentForm,
                ...data
            }));


            const contentBlock = htmlToDraft(data.deskripsi || '');
            let savedEditorState = EditorState.createEmpty();
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                savedEditorState = EditorState.createWithContent(contentState);
            }
            setEditorState(savedEditorState);
        }

    }, [snapshot]);

    const [openDialog, setOpenDialog] = useState(false)

    // Dialog Box
    const handleClickOpen = () => {
        if (isChange !== true) {
            window.history.back()
        } else {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleLogout = () => {
        window.history.back()
    }


    const handleChange = e => {

        setChange(true);

        // console.log(typeof e.target.value);
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    const handleEditorChange = newEditorState => {
        setEditorState(newEditorState);
        setError({ ...error, content: '' })
        setChange(true);
    }

    const handleDateTimeChange = name => (v) => {

        handleChange({ target: { value: Timestamp.fromDate(v), name } })
    }
    const uploadImageCallBack = async (file) => {
        try {
            const generateUploadUrl = functions.httpsCallable('upload-generateUploadUrl');

            const result = await generateUploadUrl({
                filename: file.name,
                directory: `images/events/`,
                contentType: file.type,
            })

            await axios.put(result.data.uploadUrl, file, {
                headers: {
                    'Content-Type': file.type,
                }
            })

            return { data: { link: result.data.photoUrl } }
        }
        catch {

        }
    }

    const currentContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const validate = async () => {

        const newError = { ...error };
        const { nama, tipe } = form;



        if (!nama) {
            newError.nama = 'Masukkan nama event.';
        }

        if (!tipe) {
            newError.tipe = 'Pilih tipe event.'
        }




        // if (tipe === 'offline' && !form.lokasi_offline) {
        //     newError.lokasi_offline = 'Lokasi wajib diisi.'
        // } else 
        if (tipe === 'online' && !form.lokasi_online) {
            newError.lokasi_online = 'Lokasi wajib diisi.'
        }

        if (currentContent.trim() === "<p></p>") {
            newError.deskripsi = 'Tulis deskripsi event.';
        }

        if (!form.harga_tiket_rp) {
            newError.harga_tiket_rp = 'Silakan isi harga tiket dalam rupiah.'
        }
        if (!form.harga_tiket_coin) {
            newError.harga_tiket_coin = 'Silakan isi harga tiket dalam coin.'
        }


        return newError;
    }

    const handleSubmit = status => async (e) => {

        e.preventDefault();

        const findError = await validate();

        //console.log(findError)

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)

        } else {
            setSubmitting(true)

            try {

                let newData = {
                    ...form,
                    deskripsi: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                    updated_at: Date.now(),
                    status,
                    pinNews: false,
                };
                if (status === "published") {
                    newData.published_at = Date.now()
                }

                await docRef.set(newData,
                    { merge: true })
                setChange(false);
                enqueueSnackbar('Event Berhasil Disimpan', { variant: "success" })
                if (status === "published") {

                    enqueueSnackbar('Event Berhasil Diterbitkan', { variant: "success" })
                }

                // if(form.thumbnailURL === undefined){
                //     setError("Thumbnailnya Mana")
                // }else{
                //     setError('');
                // }
            }
            catch (e) {
                const newError = {};

                newError.title = e.message;

                setError(newError);

                enqueueSnackbar(error, { variant: "error" })

            }
            setSubmitting(false)
        }
    }

    if (loading) {
        return <AppPageLoading />
    }

    console.log(form)

    return (
        <div className={classes.root}>
            <div className={classes.pageHeader}>
                <h1>Edit Event</h1>
            </div>
            <div className={classes.editorWrap}>
                <TextField
                    autoComplete="off"
                    disabled={isSubmitting}
                    id="nama"
                    name="nama"
                    className={classes.textField}
                    label="Nama Event"
                    variant="outlined"
                    value={form.nama || ''}
                    onChange={handleChange}
                    error={error.nama ? true : false}
                    helperText={error.nama}
                />
                <Box boxShadow={1} className={classes.formRow}>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName={classes.editorBox}
                        toolbarClassName={classes.toolbarBox}
                        onEditorStateChange={handleEditorChange}
                        toolbar={{
                            image: {
                                uploadCallback: uploadImageCallBack,
                                previewImage: true,
                                inputAccept: 'image/jpeg, image/jpg, image/png',
                                alt: { present: true, mandatory: true },
                                defaultSize: {
                                    height: 'auto',
                                    width: '100%',
                                }
                            },
                        }}
                    />
                    <Typography variant="caption" color="error">{error.deskripsi}</Typography>
                </Box>

                <Grid container justify="center" alignItems="center" spacing={3}>

                    {/* Thumbnail */}
                    {/* <Grid item xs={6}>
                        <UploadFoto />
                    </Grid> */}

                    {/* Pin & Kategori */}
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {/* Kategori */}
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="tipe_event"
                                    options={tipeOptions}
                                    getOptionLabel={option => option}
                                    value={form.tipe}
                                    onChange={handleAutocompleteChange('tipe')}
                                    disableClearable
                                    // loading={kategoriLoading}

                                    renderInput={params => {

                                        return (
                                            <TextField
                                                {...params}
                                                disabled={isSubmitting}
                                                className={classes.textField}
                                                label="Tipe Event"
                                                variant="outlined"
                                                fullWidth
                                                helperText={error.tipe}
                                                error={error.tipe ? true : false}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    value: form.tipe,
                                                    autoComplete: 'off',
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            {/* {form.tipe === 'offline' && <Grid item xs={12}>
                                <Autocomplete
                                    id="lokasi_offline"
                                    options={lokasiOptions}
                                    getOptionLabel={option => option}
                                    value={form.lokasi_offline}
                                    onChange={handleAutocompleteChange('lokasi_offline')}
                                    disableClearable
                                    // loading={kategoriLoading}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            disabled={isSubmitting}
                                            className={classes.textField}
                                            label="Pilih lokasi event"
                                            variant="outlined"
                                            fullWidth
                                            helperText={error.lokasi_offline}
                                            error={error.lokasi_offline ? true : false}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>} */}
                            {
                                form.tipe === 'online' && <Grid item xs={12}>
                                    <TextField
                                        disabled={isSubmitting}
                                        autoComplete="off"
                                        id="lokasi_online"
                                        name="lokasi_online"
                                        label="URL Event"
                                        fullWidth
                                        variant="outlined"
                                        value={form.lokasi_online}
                                        onChange={handleChange}
                                        helperText={error.lokasi_online}
                                        error={error.lokasi_online ? true : false}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    id="harga_tiket_rp"
                                    name="harga_tiket_rp"
                                    label="Harga Tiket (Rupiah)"
                                    type='number'
                                    fullWidth
                                    variant="outlined"
                                    value={form.harga_tiket_rp}
                                    onChange={handleChange}
                                    helperText={error.harga_tiket_rp}
                                    error={error.harga_tiket_rp ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={isSubmitting}
                                    autoComplete="off"
                                    id="harga_tiket_coin"
                                    name="harga_tiket_coin"
                                    label="Harga Tiket (Coin)"
                                    type='number'
                                    fullWidth
                                    variant="outlined"
                                    value={form.harga_tiket_coin}
                                    onChange={handleChange}
                                    helperText={error.harga_tiket_coin}
                                    error={error.harga_tiket_coin ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} container>
                                <DateTimePicker
                                    style={{ flex: 1 }}
                                    variant="inline"
                                    label="Waktu Mulai"
                                    inputVariant="outlined"
                                    // autoOk
                                    ampm={false}
                                    minDate={new Date()}
                                    value={form.start_at?.toDate?.()}
                                    onChange={handleDateTimeChange('start_at')}
                                />
                                <DateTimePicker
                                    style={{ flex: 1 }}
                                    variant="inline"
                                    label="Waktu Berakhir"
                                    inputVariant="outlined"
                                    minDate={form.start_at?.toDate?.()}
                                    // autoOk
                                    ampm={false}
                                    value={form.end_at?.toDate?.()}
                                    onChange={handleDateTimeChange('end_at')}
                                />
                            </Grid>
                            {

                                (!form.foto || !form.nama || !form.tipe || !form.harga_tiket_rp || !form.harga_tiket_coin || currentContent.trim() === "<p></p>") &&
                                <Grid container justify="center" alignItems="center" spacing={1}>
                                    <Grid item xs={2} style={{ fontSize: 35 }}>
                                        <ReportProblemOutlinedIcon color="secondary" fontSize="inherit" />
                                    </Grid>
                                    <Grid item xs={10} style={{ textAlign: 'left' }}>
                                        <Typography variant="caption">Anda Tidak Bisa Menerbitkan Event Sebelum Seluruhnya Lengkap</Typography>
                                    </Grid>
                                </Grid>
                            }
                            {/* Button */}

                            {/* Save */}
                            <Grid item xs={12}>
                                {
                                    form.status === "published" ?
                                        <Button
                                            disabled={isSubmitting}
                                            onClick={handleSubmit('published')}
                                            name="draft"
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                        >
                                            Simpan & Terbitkan
                                        </Button>
                                        :
                                        <Button
                                            disabled={isSubmitting}
                                            onClick={handleSubmit('draft')}
                                            name="draft"
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                        >
                                            Simpan Perubahan
                                        </Button>

                                }
                            </Grid>
                            {/* Publish */}
                            {
                                form.status !== "published" &&
                                <Grid item xs={12}>
                                    <Button
                                        disabled={
                                            isSubmitting ||
                                            !form.foto ||
                                            !form.nama ||
                                            !form.tipe ||
                                            !form.harga_tiket_rp ||
                                            !form.harga_tiket_coin ||
                                            currentContent.trim() === "<p></p>"
                                        }
                                        name="published"
                                        onClick={handleSubmit('published')}
                                        size="large"
                                        color="primary"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.btnAction}
                                    >
                                        Terbitkan Event
                                    </Button>
                                </Grid>
                            }
                            {/* Back */}
                            <Grid item xs={12}>
                                <Button
                                    disabled={isSubmitting}
                                    size="large"
                                    color="primary"
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleClickOpen}
                                    className={classes.btnAction}
                                >
                                    Kembali
                                </Button>
                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

            </div>


            {/* Dialog Box Logout */}

            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.dialog}
            >
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
                        </Grid>
                    </Grid>
                    <DialogTitle id="alert-dialog-slide-title"><Typography variant="body2" style={{ textAlign: 'center' }} >Perubahan Anda Belum Tersimpan, <br /> Anda Yakin Akan Keluar ?</Typography></DialogTitle>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup
                        fullWidth
                        variant="text"
                    >
                        <Button
                            onClick={handleClose}
                            color="primary"
                        >
                            Tidak
                        </Button>
                        <Button
                            onClick={handleLogout}
                        >
                            Keluar
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default NewsDetail;