import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  content: {
    margin: "150px 0",
    padding: 20,
  },

  text1: {
    marginTop: "3vh",
    marginBottom: "-1vh",
    textAlign: "center",
    fontSize: 25,
    fontWeight: 500,
    color: "grey",
  },

  text2: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    color: "grey",
    marginBottom: "4vh",
  },

  text3: {
    textAlign: "center",
    fontSize: 15,
    color: "grey",
    marginBottom: "30px",
  },

  text4: {
    textAlign: "left",
    fontSize: 13,
    fontWeight: 300,
    color: "grey",
  },

  text5: {
    marginTop: "5vh",
    textAlign: "center",
    fontSize: 11,
    fontWeight: 300,
    color: "grey",
  },

  refreshBtn: {
    marginTop: "2vh",
    marginRight: "2vw",
  },

  logoutBtn: {
    marginTop: "2vh",
    marginLeft: "2vw",
    border: "1px solid" + theme.palette.primary.main,
  },
}));

export default useStyles;
