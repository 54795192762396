import React, { useEffect, useState } from "react";

import useStyles from "./styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useCollection } from "react-firebase-hooks/firestore";
import { functions, useFirebase } from "../../../components/FirebaseProvider";
import { Link } from "react-router-dom";

import PageLoading from "../../../components/AppPageLoading";
import HarapanChart from "./harapan";

function Home() {
  const classes = useStyles();

  const { firestore } = useFirebase();
  const countDoc = firestore.collection("counters");
  const [count, loading] = useCollection(countDoc);
  const [harapan, setHarapan] = useState([]);
  const [countItems, setCountItems] = useState([]);
  const [utm, setUtm] = useState([]);
  useEffect(() => {
    const countHarapan = functions.httpsCallable("statistic-countHarapan");

    countHarapan().then((value) => {
      setHarapan(
        Object.entries(value.data ?? {})
          .map((item) => {
            const [name, value] = item;
            return { name, value };
          })
          .filter((item) => {
            return !["null", "not_null", "total_siswa"].includes(item.name);
          })
      );
    });
  }, []);

  useEffect(() => {
    const countUtm = functions.httpsCallable("statistic-countUtm");

    countUtm().then((value) => {
      setUtm(
        Object.entries(value.data ?? {})
          .map((item) => {
            const [name, value] = item;
            // let newName = name;
            // if (name === 'null') {
            //     newName = 'direct';
            // }
            return { name, value };
          })
          .filter((item) => {
            return !["null", "not_null", "total_siswa", "direct"].includes(
              item.name
            );
          })
      );
    });
  }, []);
  useEffect(() => {
    if (count) {
      setCountItems(
        count.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
    }
  }, [count]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      {/* <h1 className={classes.pageTitle}>Dashboard</h1> */}
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={3}
        className={classes.cardC}
      >
        {countItems.map((count) => {
          //
          let title = "";
          let information = "";
          let link = "";
          //
          if (count.id === "bk") {
            title = "Jumlah Akun Guru BK";
            information = "Guru";
            link = "/akun/bk";
          }
          if (count.id === "sekolah") {
            title = "Jumlah SLTA";
            information = "Sekolah";
            link = "/data/sekolah";
          }
          if (count.id === "siswa") {
            title = "Jumlah Akun Siswa";
            information = "Siswa";
          }
          if (count.id === "peserta_event") {
            title = "Jumlah Peserta Event";
            information = "Peserta";
          }
          if (count.id === "mitra") {
            title = "Jumlah Akun Mitra";
            information = "Mitra";
            link = "/akun/mitra";
          }
          //
          // Format
          let Count = count.count;
          //
          let number_string = Count.toString();
          let sisa = number_string.length % 3;
          let number = number_string.substr(0, sisa);
          let ribuan = number_string.substr(sisa).match(/\d{3}/g);
          //
          if (ribuan) {
            let separator = sisa ? "." : " ";
            number += separator + ribuan.join(".");
          }
          //
          return (
            <Grid key={count.id} item xs={12} sm={3} md={3} lg={3}>
              <Card className={classes.cardColor1}>
                <CardContent>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        {title}
                      </Typography>
                      <Typography className={classes.text1b}>
                        Yang Terdaftar di Aplikasi karir.ai
                      </Typography>
                      <Typography className={classes.text1c}>
                        {number} {information}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Button
                        component={Link}
                        className={classes.btn}
                        variant="outlined"
                        to={link}
                        disabled={count.id === "siswa"}
                      >
                        Lihat Detail
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}

        {
          // harapan
        }
        <Grid key={count.id} item xs={12} sm={4}>
          <Card className={classes.cardColor1}>
            <CardContent>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={3}
                className={classes.cardI}
              >
                <Grid item xs={12}>
                  <Typography className={classes.text1a}>Harapan Para Pengguna karir.ai</Typography>
                  {/* <Typography className={classes.text1b}>
                    Siswa Yang Terdaftar di Aplikasi karir.ai
                  </Typography> */}
                  <div>
                    <HarapanChart data={harapan} />
                  </div>
                </Grid>
                {/* <Grid container justify="flex-start" alignItems="flex-start">
                                <Button
                                    component={Link}
                                    className={classes.btn}
                                    variant="outlined"
                                    // to={link}
                                    disabled={count.id === "siswa"}
                                >
                                    Lihat Detail
                                </Button>
                            </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {
          // utm source stats
        }
        <Grid key={count.id} item xs={12} sm={4}>
          <Card className={classes.cardColor1}>
            <CardContent>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={3}
                className={classes.cardI}
              >
                <Grid item xs={12}>
                  <Typography className={classes.text1a}>UTM Source</Typography>
                  {/* <Typography className={classes.text1b}>
                    Aplikasi karir.ai
                  </Typography> */}
                  <div>
                    <HarapanChart data={utm} />
                  </div>
                </Grid>
                {/* <Grid container justify="flex-start" alignItems="flex-start">
                                <Button
                                    component={Link}
                                    className={classes.btn}
                                    variant="outlined"
                                    // to={link}
                                    disabled={count.id === "siswa"}
                                >
                                    Lihat Detail
                                </Button>
                            </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
