// React
import React, { useState } from 'react';

// 
import useStyles from './styles';

// 
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

// Utils
import { functions } from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';

function Broadcast() {
    // 
    const classes = useStyles()
    // 
    const { enqueueSnackbar } = useSnackbar()
    // 
    const [broadcast, setBroadcast] = useState({
        title: '',
        body: ''
    })
    // 
    const [isSubmitting, setSubmitting] = useState(false)
    // 
    const [error, setError] = useState({
        title: '',
        body: ''
    })
    // 
    const handleChange = (e) => {
        const { name, value } = e.target;

        setBroadcast(broadcast => ({
            ...broadcast,
            [name]: value
        }))

        setError(error => ({
            ...error,
            [name]: ''
        }))
    }
    // 
    const validate = () => {
        const newError = { ...error };
        const { title, body } = broadcast;

        if (!title) {
            newError.title = 'Judul Notifikasi Wajib Diisi';
        }

        if (!body) {
            newError.body = 'Konten Notifikasi Wajib Diisi';
        }

        return newError;
    }
    // 
    const sendBroadcast = async e => {
        e.preventDefault();
        const findError = validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            try {
                const broadcastNotif = functions.httpsCallable('broadcastNotif');

                setSubmitting(true)

                await broadcastNotif({
                    title: broadcast.title,
                    body: broadcast.body
                });

                setBroadcast({
                    title: '',
                    body: ''
                })

                setSubmitting(false)

                enqueueSnackbar("Broadcast Notifikasi Berhasil Dikirim", { variant: "success" })

            } catch (error) {
                console.log(error)
            }
        }
    }
    // 
    return (
        <div className={classes.root}>
            <Paper square className={classes.paper}>
                <TextField
                    name="title"
                    variant="outlined"
                    color="primary"
                    label="Judul Notifikasi"
                    className={classes.title}
                    value={broadcast.title}
                    onChange={handleChange}
                    error={error.title ? true : false}
                    helperText={error.title}
                    autoComplete="off"
                />
                <TextField
                    name="body"
                    variant="outlined"
                    color="primary"
                    label="Konten Notifikasi"
                    className={classes.body}
                    value={broadcast.body}
                    onChange={handleChange}
                    error={error.body ? true : false}
                    helperText={error.body}
                    autoComplete="off"
                    multiline
                    rows={8}
                />
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    disabled={isSubmitting}
                    onClick={sendBroadcast}
                >
                    Kirim Notifikasi
                </Button>
            </Paper>
        </div>
    )
}

export default Broadcast;