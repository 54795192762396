// React
import React, { useState, useEffect } from 'react';

//Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// Styles
import useStyles from './styles';

// MUI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

//Utils
import { firestore, functions, Arr } from '../../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';
import AppPageLoading from '../../../components/AppPageLoading';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function MisiDetail(props) {
    const classes = useStyles();

    const params = useParams();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const docRef = firestore.doc(`misi/${params.misiId}`)
    const [snapshot, loading] = useDocument(docRef);

    const [error, setError] = useState({
        content: ''
    })

    const [isSubmitting, setSubmitting] = useState(false);

    const [isChange, setChange] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (snapshot) {

            const data = snapshot.data()

            const contentBlock = htmlToDraft(data.content || '');
            let savedEditorState = EditorState.createEmpty();
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                savedEditorState = EditorState.createWithContent(contentState);
            }
            setEditorState(savedEditorState);
        }

    }, [snapshot]);

    const [openDialog, setOpenDialog] = useState(false)

    // Dialog Box
    const handleClickOpen = () => {
        if (isChange !== true) {
            window.history.back()
        } else {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleLogout = () => {
        window.history.back()
    }


    const handleEditorChange = newEditorState => {
        setEditorState(newEditorState);
        setError({ ...error, content: '' })
        setChange(true);
    }

    const uploadImageCallBack = async (file) => {
        try {
            const generateUploadUrl = functions.httpsCallable('upload-generateUploadUrl');

            const result = await generateUploadUrl({
                filename: file.name,
                directory: `images/misi/`,
                contentType: file.type,
            })

            await axios.put(result.data.uploadUrl, file, {
                headers: {
                    'Content-Type': file.type,
                }
            })

            return { data: { link: result.data.photoUrl } }
        }
        catch{

        }
    }

    const validate = async () => {

        const newError = { ...error };

        const currentContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        if (currentContent.trim() === "<p></p>") {
            newError.content = 'Tulis Konten';
        }

        return newError;
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        const findError = await validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)

            try {

                await docRef.set({
                    content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                    updatedAt: Arr.serverTimestamp(),
                }, { merge: true })

                setChange(false);
                enqueueSnackbar('Misi Berhasil Disimpan', { variant: "success" })

            }
            catch (e) {

                enqueueSnackbar(e.message, { variant: "error" })

            }
            setSubmitting(false)
        }
    }

    if (loading) {
        return <AppPageLoading />
    }

    return (
        <div className={classes.root}>
            <div className={classes.pageHeader}>
                <h1>Edit Misi</h1>
            </div>
            <div className={classes.editorWrap}>
                <Box boxShadow={1} className={classes.formRow}>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName={classes.editorBox}
                        toolbarClassName={classes.toolbarBox}
                        onEditorStateChange={handleEditorChange}
                        toolbar={{
                            image: {
                                uploadCallback: uploadImageCallBack,
                                previewImage: true,
                                inputAccept: 'image/jpeg, image/jpg, image/png',
                                alt: { present: true, mandatory: true },
                                defaultSize: {
                                    height: 'auto',
                                    width: '100%',
                                }
                            },
                        }}
                    />
                    <Typography variant="caption" color="error">{error.content}</Typography>
                </Box>

                <Grid container justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            {/* Save */}
                            <Grid item xs={12}>
                                <Button
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}
                                    name="draft"
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                >
                                    Simpan Perubahan
                                </Button>
                            </Grid>
                            {/* Back */}
                            <Grid item xs={12}>
                                <Button
                                    disabled={isSubmitting}
                                    size="large"
                                    color="primary"
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleClickOpen}
                                    className={classes.btnAction}
                                >
                                    Kembali
                                </Button>
                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

            </div>


            {/* Dialog Box Logout */}

            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.dialog}
            >
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
                        </Grid>
                    </Grid>
                    <DialogTitle id="alert-dialog-slide-title"><Typography variant="body2" style={{ textAlign: 'center' }} >Perubahan Anda Belum Tersimpan, <br /> Anda Yakin Akan Keluar ?</Typography></DialogTitle>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup
                        fullWidth
                        variant="text"
                    >
                        <Button
                            onClick={handleClose}
                            color="primary"
                        >
                            Tidak
                    </Button>
                        <Button
                            onClick={handleLogout}
                        >
                            Keluar
                    </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MisiDetail;