import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { firestore, useFirebase } from '../../../components/FirebaseProvider';
import { useCollection } from 'react-firebase-hooks/firestore';
import useStyles from './styles';

import MaterialTable from '@material-table/core';
import AppPageLoading from '../../../components/AppPageLoading';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fab from '@material-ui/core/Fab';
import PostAddIcon from '@material-ui/icons/PostAdd';

import { formatDate1 } from '../../../utils/formatter';
import { useSnackbar } from 'notistack';
import update from 'immutability-helper';
import getTableTitle from '../../../utils/getTableTitle';

function SoalColumn({ soal }) {
    const classes = useStyles();
    const [isActive, setActive] = useState(false);
    const handleMore = () => {
        setActive(!isActive);
    };

    if (soal?.length <= 100) {
        return <div>

            <div className={classes.closed} dangerouslySetInnerHTML={{ __html: soal }}></div>

        </div>
    }
    return (
        <div>
            {/* <div className={isActive ? classes.expanded : classes.closed} dangerouslySetInnerHTML={{ __html: soal }}></div> */}
            <div className={isActive ? classes.expanded : classes.closed} dangerouslySetInnerHTML={{ __html: soal }}></div>
            <button className={classes.btnMore} onClick={handleMore}>{isActive ? "Hide..." : "More..."}</button>
        </div>
    )
}

const columns = {
    soal: [
        { title: getTableTitle('Kode Soal'), field: 'kode_soal' },
        {
            title: getTableTitle('Soal'), field: 'pertanyaan',
            render: rowData => {
                // const soal = rowData.pertanyaan;//.replace(/"<p>"|[</p>]|[^a-zA-Z ]/g, '');
                // const [isActive, setActive] = useState(false);
                // // const handleMore = () => {
                // //     setActive(!isActive);
                // // };
                // return (
                //     // <Typography noWrap variant="subtitle2" >{soal}</Typography>
                //     // <div>
                //         <div className={isActive ? classes.expanded : classes.closed}  dangerouslySetInnerHTML={{ __html: soal}}></div>
                //         // <div className={isActive ? classes.expanded : classes.closed} dangerouslySetInnerHTML={{ __html: soal}}></div>
                //     //  <button className={classes.btnMore} onClick={handleMore}>More</button>
                //     //</div> 
                // )

                return <SoalColumn soal={rowData.pertanyaan} />
            }
        },
        {
            title: getTableTitle('Dibuat Pada'), field: 'createdAt',
            render: rowData =>
                <Typography variant="subtitle2">{formatDate1(rowData.createdAt)}</Typography>
        },
        {
            title: getTableTitle('Diperbarui Pada'), field: 'updatedAt',
            render: rowData =>
                <Typography variant="subtitle2">{formatDate1(rowData.updatedAt)}</Typography>
        },
    ],
}

function Soal() {
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const { form, setForm } = useFirebase();

    const queryTryout = firestore.collection(`tryout`);
    const [tryoutSnapshot, tryoutLoading] = useCollection(queryTryout);
    const queryKategori = firestore.collection('kategori_soal');
    const [kategoriSnapshot, kategoriLoading] = useCollection(queryKategori);

    const [table, setTable] = useState({
        columns: columns.soal,
        data: [],
    });

    const [loading, setLoading] = useState(false);

    const [kategoriOptions, setKategoriOptions] = useState([]);
    const [tryoutOptions, setTryoutOptions] = useState([]);

    const handleAutocompleteChange = name => (e, v) => {
        handleChange({ target: { value: v, name } })
    }

    const handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

    }

    useEffect(() => {
        if (kategoriSnapshot && tryoutSnapshot && !kategoriSnapshot.empty && !tryoutSnapshot.empty) {
            setKategoriOptions(
                kategoriSnapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        nama: doc.data().title
                    }
                })
            );
            setTryoutOptions(
                tryoutSnapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        nama: doc.data().judul
                    }
                })
            )
        }
    }, [kategoriSnapshot, tryoutSnapshot])

    const handleClear = () => {
        setForm({
            kategori_soal: {
                id: '',
                nama: ''
            },
            tryout: {
                id: '',
                nama: ''
            }
        })
    }

    useEffect(() => {
        if (form.kategori_soal.id) {
            const querySoal = async () => {
                setLoading(true)
                const snapshot = await firestore.collection('soal').where("tryout.id", "==", form.tryout.id).where("kategori_soal.id", "==", form.kategori_soal.id).get()

                setTable(table => {
                    return {
                        ...table,
                        data: snapshot.docs.map(doc => {
                            return {
                                uid: doc.id,
                                ...doc.data()
                            }
                        })
                    }
                })
                setLoading(false)
            }
            querySoal()
        } else {
            setTable(table => {
                return {
                    ...table,
                    data: []
                }
            })
        }
    }, [form.kategori_soal, form.tryout])

    let title = "Soal List"

    return <>
        <Grid container justify="center" alignItems="center" spacing={2} style={{ margin: 10 }}>
            <Grid item xs={4}>
                <Autocomplete
                    id="tryout"
                    options={tryoutOptions}
                    getOptionLabel={option => option.nama}
                    value={form.tryout}
                    onChange={handleAutocompleteChange('tryout')}
                    disableClearable
                    loading={tryoutLoading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            label="Judul Tryout"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off',
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    id="category"
                    options={kategoriOptions}
                    getOptionLabel={option => option.nama}
                    value={form.kategori_soal}
                    onChange={handleAutocompleteChange('kategori_soal')}
                    disableClearable
                    loading={kategoriLoading}
                    renderInput={params => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            label="Kategori Soal"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off',
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    onClick={handleClear}
                >
                    <ClearIcon />
                </IconButton>
            </Grid>
        </Grid>
        {
            loading ?
                <AppPageLoading />
                :
                form.kategori_soal.id ?
                    <>
                        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2} style={{ marginBottom: 10 }}>
                            <Grid item style={{ fontSize: 35 }}>
                                <ReportProblemOutlinedIcon color="secondary" fontSize="inherit" />
                            </Grid>
                            <Grid item style={{ textAlign: 'left' }}>
                                <Typography variant="caption">Saat Anda Menambah Data Baru, Data Judul Tryout Akan Diambil Dari Pilihan Diatas.</Typography>
                                <br />
                                <Typography variant="caption">Pastikan Pilihan Anda Sesuai Dengan Data Yang Akan Ditambahkan</Typography>
                            </Grid>
                        </Grid>
                        <MaterialTable
                            options={{ thirdSortClick: false }}
                            title={title}
                            columns={table.columns}
                            data={table.data}
                            actions={
                                [
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit Tryout',
                                        onClick: (event, data) => {
                                            history.push(`/tryout/edit/${data.uid}`);
                                        }
                                    },
                                ]
                            }
                            editable={
                                {
                                    onRowDelete: async (oldData) => {
                                        // await firestore.doc(`tryout/${oldData.tryout.id}`).set({
                                        //     jumlah_soal: Arr.increment(-1),
                                        //     jumlah_soal_perkategori: {
                                        //         [oldData.kategori_soal.id]: {
                                        //             total: Arr.increment(-1)
                                        //         }
                                        //     }
                                        // }, { merge: true })

                                        const queryTryout = firestore.doc(`tryout/${oldData.tryout.id}`);
                                        await firestore.runTransaction((transaction) => {
                                            return transaction.get(queryTryout).then((doc) => {

                                                const jumlah_soal = doc.data().jumlah_soal + 1;
                                                let curr_jumlah_soal_perkategori = doc.data().jumlah_soal_perkategori || [];
                                                let jumlah_soal_perkategori = [];
                                                if (!Array.isArray(curr_jumlah_soal_perkategori)) {
                                                    curr_jumlah_soal_perkategori = Object.entries(curr_jumlah_soal_perkategori).map((item) => {

                                                        const [key, val] = item;

                                                        return {
                                                            kategori: key,
                                                            ...val
                                                        }
                                                    })
                                                }

                                                const findKategori = curr_jumlah_soal_perkategori.find(c => c.kategori === oldData.kategori_soal.id);

                                                if (findKategori) {
                                                    jumlah_soal_perkategori = curr_jumlah_soal_perkategori.map((c) => {
                                                        if (c.kategori === oldData.kategori_soal.id) {

                                                            return {
                                                                ...c,
                                                                total: c.total - 1,
                                                            }
                                                        }

                                                        return c;
                                                    })
                                                }

                                                transaction.update(queryTryout, {
                                                    jumlah_soal,
                                                    jumlah_soal_perkategori
                                                });
                                                // return jumlah_soal;
                                            });
                                        });
                                        await firestore.doc(`soal/${oldData.uid}`).delete()
                                        await firestore.doc(`kunci_jawaban/${oldData.uid}`).delete()
                                        setTable(table => {
                                            return {
                                                ...table,
                                                data: update(table.data, { $splice: [[oldData.tableData.id, 1]] })
                                            }
                                        })
                                        enqueueSnackbar('Data Berhasil Dihapus', { variant: "success" })
                                    }
                                }
                            }
                            localization={{
                                body: {
                                    emptyDataSourceMessage: title + ' Tidak Ada',
                                    addTooltip: 'Tambah',
                                    editTooltip: 'Ubah',
                                    deleteTooltip: 'Hapus',
                                    editRow: {
                                        deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                                        cancelTooltip: 'Batal',
                                        saveTooltip: 'Ya'
                                    }
                                },
                                toolbar: {
                                    searchTooltip: 'Cari',
                                    searchPlaceholder: 'Cari Data'
                                },
                                header: {
                                    actions: 'Tindakan'
                                },
                                pagination: {
                                    labelRowsSelect: 'Baris',
                                    labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
                                    firstTooltip: 'Halaman Pertama',
                                    previousTooltip: 'Halaman Sebelumnya',
                                    nextTooltip: 'Halaman Selanjutnya',
                                    lastTooltip: 'Halaman Terakhir'
                                }
                            }}
                        />
                    </>
                    :
                    <Grid container direction="column" justify="center" alignItems="center" style={{ margin: '10% 0', paddingBottom: '7.5%' }}>
                        <Grid item style={{ fontSize: 40 }}>
                            <InfoOutlinedIcon htmlColor="grey" fontSize="inherit" />
                        </Grid>
                        <Grid item>
                            <Typography>Silahkan Pilih Judul Tryout & Kategori Soal Terlebih Dahulu</Typography>
                            <Typography variant="caption">Data Soal Akan Tampil Sesuai Judul Tryout & Kategori Soal Yang Dipilih</Typography>
                        </Grid>
                    </Grid>
        }
        <Fab
            className={classes.fab1}
            color="primary"
            onClick={() => history.push(`/tryout/tambah/${form.tryout.id}`)}
            disabled={!form.tryout.id}
            tooltip
        >
            <PostAddIcon />
        </Fab>
    </>
}

export default Soal;