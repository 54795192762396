import React, { useState, useEffect } from 'react';

// material ui
import Fab from '@material-ui/core/Fab';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { useFirebase } from '../../../../components/FirebaseProvider';

// styles
import useStyles from './styles';

import { useCollection } from 'react-firebase-hooks/firestore';
// page component
import Dialog from './dialog';
import AppPageLoading from '../../../../components/AppPageLoading';

import MaterialTable from '@material-table/core';
import getTableTitle from '../../../../utils/getTableTitle';

function Bk() {
    const classes = useStyles();
    const { firestore } = useFirebase();

    const queryBk = firestore.collection('bk');

    const [snapshot, loading] = useCollection(queryBk)
    const [dialog, setDialog] = useState({
        mode: 'Tambah',
        open: false,
        data: {}
    });

    const [table, setTable] = React.useState({
        columns: [
            { title: getTableTitle('Nama'), field: 'nama' },
            { title: getTableTitle('Email'), field: 'email' },
            { title: getTableTitle('Sekolah'), field: 'sekolah.nama' },
            { title: getTableTitle('Provinsi'), field: 'provinsi.nama' },
            { title: getTableTitle('Kota'), field: 'kota.nama' },

        ],
        data: [
        ],
    });

    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            setTable(table => {
                return {
                    ...table,
                    data: snapshot.docs.map(doc => {
                        return {
                            uid: doc.id,
                            ...doc.data()
                        }
                    })
                }
            })
        } else {
            setTable(table => {
                return {
                    ...table,
                    data: []
                }
            })
        }
    }, [snapshot]);



    if (loading) {
        return <AppPageLoading />
    }
    return <>

        <MaterialTable
            options={{ thirdSortClick: false }}
            title="Data Guru BK"
            columns={table.columns}
            data={table.data}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Edit',
                    onClick: (event, data) => {
                        //console.log(rowData)
                        setDialog({
                            mode: 'Edit',
                            data,
                            open: true
                        })
                    }
                },
            ]}
            editable={{

                onRowDelete: async (oldData) => {
                    await firestore.doc(`bk/${oldData.uid}`).delete();
                    await firestore.doc(`roles/${oldData.uid}`).delete()
                }
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Tidak Ada Data',
                    addTooltip: 'Tambah',
                    editTooltip: 'Ubah',
                    deleteTooltip: 'Hapus',
                    editRow: {
                        deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                        cancelTooltip: 'Batal',
                        saveTooltip: 'Ya'
                    }
                },
                toolbar: {
                    searchTooltip: 'Cari',
                    searchPlaceholder: 'Cari Data'
                },
                header: {
                    actions: 'Tindakan'
                },
                pagination: {
                    labelRowsSelect: 'Baris',
                    labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
                    firstTooltip: 'Halaman Pertama',
                    previousTooltip: 'Halaman Sebelumnya',
                    nextTooltip: 'Halaman Selanjutnya',
                    lastTooltip: 'Halaman Terakhir'
                }
            }}
        />
        <Fab
            className={classes.fab}
            color="primary"
            onClick={(e) => {
                setDialog({ mode: 'Tambah', open: true, data: {} });
            }}
        >
            <PersonAddOutlinedIcon />
        </Fab>
        <Dialog
            dialog={dialog}
            handleClose={() => {
                setDialog({ mode: 'Tambah', open: false, data: {} });
            }}
        />
    </>
}

export default Bk;