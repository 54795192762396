import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';

// utils
import { withRouter, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { firestore, Arr } from "../../../components/FirebaseProvider";
import { useDocument } from "react-firebase-hooks/firestore";
import useStyles from "./styles";
import { currency } from "../../../utils/formatter-rupiah";
import { unixToTime } from "../../../utils/formatter";
import UploadFoto from "./uploadFoto";
import TextField from "@material-ui/core/TextField";

function AddDialog({ dialog: { mode, open, data }, handleClose }) {
  const params = useParams();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [photo, setPhoto] = useState();
  const [reason, setReason] = useState({
    open: false,
  });
  const [alasan, setAlasan] = useState("");

  let doc = "";
  let Doc = "";
  let Title = "";
  if (data && data.uid) {
    const DocMitra = firestore.doc(`pencairan_mitra/${data.uid}`);
    const Coin = firestore.doc(`koin_mitra/${data.mitra_id}`);
    const DocBk = firestore.doc(`pencairan_bk/${data.uid}`);
    const CoinBk = firestore.doc(`perSekolah/${data.sekolah_id}`);
    if (params.status === "mitra") {
      doc = DocMitra;
      Title = "Mitra";
      Doc = Coin;
    }
    if (params.status === "bk") {
      doc = DocBk;
      Title = "Bk";
      Doc = CoinBk;
    }
  }

  const [snapshot, load] = useDocument(doc);

  useEffect(() => {
    if (snapshot) {
      setPhoto({
        ...snapshot.data(),
      });
    }
  }, [snapshot]);

  if (load) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const coin = data.total_pencairan + data.biaya_transfer;
    setSubmitting(true);
    try {
      if (mode === "review") {
        await doc.set(
          {
            status: "success",
            updated_at: Arr.serverTimestamp(),
          },
          { merge: true }
        );
        if (params.status === "mitra") {
          await Doc.set(
            {
              in_request: false,
              total_coin: Arr.increment(-coin / 100),
              updated_at: Arr.serverTimestamp(),
            },
            { merge: true }
          );
        }
        if (params.status === "bk") {
          await Doc.set(
            {
              in_request: false,
              saldo: Arr.increment(-coin),
              updated_at: Arr.serverTimestamp(),
            },
            { merge: true }
          );
        }
        handleClose();
        enqueueSnackbar(`Pencairan ${Title} berhasil`, { variant: "success" });
      }
    } catch (e) {
      console.log(e.message);
    }
    setSubmitting(false);
  };

  const handleRejected = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      if (mode === "review") {
        await doc.set(
          {
            status: "rejected",
            updated_at: Arr.serverTimestamp(),
          },
          { merge: true }
        );
        await Doc.set(
          {
            in_request: false,
            updated_at: Arr.serverTimestamp(),
          },
          { merge: true }
        );
        setReason((reason) => {
          return {
            ...reason,
            open: true,
          };
        });
        handleClose();
        enqueueSnackbar(`Pencairan ${Title} dibatalkan`, { variant: "error" });
      }
    } catch (e) {
      console.log(e.message);
    }
    setSubmitting(false);
  };

  const handleUpload = () => {
    setSubmitting(true);
    handleClose();
    enqueueSnackbar("Bukti Transfer Berhasil Diupload", { variant: "success" });
    setSubmitting(false);
  };

  const hadleAlasan = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      if (mode === "review") {
        await doc.set(
          {
            reason: alasan,
            updated_at: Arr.serverTimestamp(),
          },
          { merge: true }
        );
        setReason((reason) => {
          return {
            ...reason,
            open: false,
          };
        });
        enqueueSnackbar(`Alasan dibatalkan telah dikirim`, {
          variant: "success",
        });
      }
    } catch (e) {
      console.log(e.message);
    }
    setSubmitting(false);
  };

  let title = "BK";

  if (params.status === "mitra") {
    title = "Mitra";
  }

  const disabled = photo && photo.photoUrl;

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-title"
        >{`Pencairan Fee ${title}`}</DialogTitle>
        {mode === "review" ? (
          <IconButton
            aria-label="close"
            className={classes.IconButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <DialogContent dividers>
          <Grid className={classes.wrapContent}>
            <Grid className={classes.wrapBody}>
              {mode === "review" ? (
                <>
                  <Typography className={classes.wrapTitle}>
                    Detail Pencairan Fee {data.nama_pemilik}
                  </Typography>
                  <Grid className={classes.wrapdetail}>
                    <Typography>Nama Pemilik : {data.nama_pemilik}</Typography>
                    <Typography>Nama Bank : {data.bank}</Typography>
                    <Typography>Nomor Rekening : {data.no_rekening}</Typography>
                    <Typography>
                      Biaya Transfer : {currency(data.biaya_transfer)}
                    </Typography>
                    <Typography>
                      Nominal Pencairan : {currency(data.total_pencairan)}
                    </Typography>
                    <Typography>
                      Tanggal Pencairan :{" "}
                      {unixToTime(
                        data.created_at
                          ? data.created_at.toMillis()
                          : data.updated_at.toMillis()
                      )}
                    </Typography>
                  </Grid>
                </>
              ) : mode === "upload" ? (
                <UploadFoto data={data} />
              ) : (
                mode === "show" && (
                  <Grid item xs={12} className={classes.thumbPLayout}>
                    <img
                      className={classes.thumbPreview}
                      src={data.photoUrl}
                      alt=""
                    />
                  </Grid>
                )
              )}
            </Grid>
            <DialogActions className={classes.wrapActions}>
              {mode === "review" ? (
                <>
                  <Button
                    onClick={handleRejected}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="error"
                  >
                    Ditolak
                  </Button>
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Selesai
                  </Button>
                </>
              ) : mode === "upload" ? (
                <Button
                  fullWidth
                  onClick={handleUpload}
                  disabled={isSubmitting || !disabled}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Upload Bukti
                </Button>
              ) : (
                mode === "show" && (
                  <Button
                    fullWidth
                    onClick={() => handleClose()}
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Kembali
                  </Button>
                )
              )}
            </DialogActions>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={reason.open}
        aria-labelledby="alert-dialog-ttl"
        aria-describedby="alert-dialog-desc"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-ttl"
        >{`Pencairan Fee ${title}`}</DialogTitle>
        <DialogContent dividers>
          <Grid className={classes.wrapContent}>
            <Grid className={classes.wrapBody}>
              <Typography align="left">
                Sertakan Alasan Menolak Pencairan
              </Typography>
              <TextField
                style={{ marginTop: 15 }}
                id="reason"
                name="reason"
                value={alasan}
                onChange={(e) => setAlasan(e.target.value)}
                label="Alasan Menolak Pencairan"
                multiline
                rows="4"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <DialogActions className={classes.wrapActions}>
              <Button
                fullWidth
                onClick={hadleAlasan}
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                Kirim
              </Button>
            </DialogActions>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

AddDialog.propTypes = {
  dialog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withRouter(AddDialog);
