let firebaseConfig = {
  apiKey: "AIzaSyBbCihMaSG08HJGitr6VJJAd5MBjxAU4yc",
  authDomain: "karirai-dev.firebaseapp.com",
  databaseURL:
    "https://karirai-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "karirai-dev",
  storageBucket: "karirai-dev.appspot.com",
  messagingSenderId: "934338373927",
  appId: "1:934338373927:web:efd849811c050cc57c2102",
  measurementId: "G-0316J2P21T",
};

if (process.env.REACT_APP_FIREBASE_PROD === "YES") {
  firebaseConfig = {
  apiKey: "AIzaSyCfWPv9PvB8fgxdes2pMiiDvR8l4EZJJXs",
  authDomain: "karirai-prod.firebaseapp.com",
  projectId: "karirai-prod",
  storageBucket: "karirai-prod.appspot.com",
  messagingSenderId: "498302587148",
  appId: "1:498302587148:web:79a5ad832096979015f929",
  measurementId: "G-08MB7G171Z"
};
}

export default firebaseConfig;
