import React, { useState, useEffect } from "react";

import { useParams, useHistory } from "react-router-dom";

import useStyles from "./styles";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import Typography from "@material-ui/core/Typography";

import { firestore } from "../../../components/FirebaseProvider";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

import Switch from "@material-ui/core/Switch";
import MaterialTable from "@material-table/core";
import AppPageLoading from "../../../components/AppPageLoading";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSnackbar } from "notistack";
import Dialog from "./dialog";
import AddMisi from "./addMisi";
import { delimiters } from "../../../utils/formatter-rupiah";
import { formatDate } from "../../../utils/formatter";
// import Avatar from "@material-ui/core/Avatar";
import getTableTitle from "../../../utils/getTableTitle";
const defaultMisiRef = firestore.collection("misi").doc("default");

function KetColumn({ keterangan }) {
  const classes = useStyles();
  const [isActive, setActive] = useState(false);
  const handleMore = () => {
    setActive(!isActive);
  };

  if (keterangan?.length <= 100) {
    return (
      <div>
        <div
          className={classes.closed}
          dangerouslySetInnerHTML={{ __html: keterangan }}
        ></div>
      </div>
    );
  }
  return (
    <div>
      {/* <div className={isActive ? classes.expanded : classes.closed} dangerouslySetInnerHTML={{ __html: soal }}></div> */}
      <div
        className={isActive ? classes.expanded : classes.closed}
        dangerouslySetInnerHTML={{ __html: keterangan }}
      ></div>
      <button className={classes.btnMore} onClick={handleMore}>
        {isActive ? "Hide..." : "More..."}
      </button>
    </div>
  );
}

const columns = {
  kelola: [
    {
      title: getTableTitle("Hadiah"),
      field: "reward",
      render: (rowData) => <span>{delimiters(rowData.reward)}</span>,
    },
    { title: getTableTitle("Judul Misi"), field: "judul" },
    {
      title: getTableTitle("Keterangan"),
      field: "content",
      render: (rowData) => {
        const keterangan = rowData?.content?.replace?.(/<p>/g, "");
        const ket = keterangan?.replace("</p>", "");
        return (
          // <Typography noWrap style={{ width: 520 }} variant="subtitle2">
          //   {ket}
          // </Typography>
          <div>
            <KetColumn keterangan={ket} />
          </div>
        );
      },
    },
    { title: getTableTitle("Urutan"), field: "order" },
    {
      title: getTableTitle("Status"),
      field: "status_misi",
      render: (rowData) => (
        <>
          {rowData.status_misi === "active" ? (
            <AttachMoneyIcon style={{ color: "green" }} />
          ) : (
            <AttachMoneyIcon style={{ color: "red" }} />
          )}
        </>
      ),
    },
    {
      title: "Default",
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <Switch
              checked={rowData.default_misi_id === rowData.uid}
              onChange={() => {
                defaultMisiRef.set(
                  {
                    misi_id: rowData.uid,
                  },
                  { merge: true }
                );
              }}
              name={"checked-" + rowData?.uid}
              inputProps={{ "aria-label": "default" }}
            />
          </>
        );
      },
    },
  ],
  review: [
    { title: getTableTitle("Nama"), field: "user.nama" },
    { title: getTableTitle("Email"), field: "user.email" },
    {
      title: getTableTitle("Tanggal"),
      field: "tanggal",
      defaultSort: "desc",
      render: (rowData) => <span>{formatDate(rowData.tanggal)}</span>,
    },
    { title: getTableTitle("Jumlah Coin"), field: "jumlah_coin" },
    // {
    //   title: "Bukti Misi",
    //   field: "photoURL",
    //   sorting: false,
    //   render: (rowData) => (
    //     <Avatar variant="square" alt="bukti" src={rowData.photoURL} />
    //   ),
    // },
  ],
};
function Tabel() {
  const history = useHistory();

  const params = useParams();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const queryMisi =
    params.status === "kelola"
      ? firestore.collection("misi").where("kategori", "==", "misi")
      : firestore.collection("misi").where("status", "==", params.status);

  const [data, loading] = useCollectionData(queryMisi, { idField: "uid" });

  const [defaultMisi, loadingDefaultMisi] = useDocumentData(defaultMisiRef);
  const [columnsSelected, setColumnSelected] = useState();
  const [title, setTitle] = useState("Kelola Misai");
  const [dialog, setDialog] = useState({
    open: false,
    data: [],
  });

  const [addDialog, setAddDialog] = useState({
    open: false,
    mode: "",
    data: [],
  });

  useEffect(() => {
    console.log(params.status, "how many times");
    setColumnSelected(
      params.status === "kelola" ? columns.kelola : columns.review
    );

    let title = "Kelola Misi";

    if (params.status === "review") {
      title = "Misi Ditinjau";
    }
    if (params.status === "valid") {
      title = "Misi Dikonfirmasi";
    }
    if (params.status === "rejected") {
      title = "Misi Ditolak";
    }
    setTitle(title);
  }, [params.status]);

  // const columnsSelected = useMemo(() => params.status === 'kelola' ? columns.kelola : columns.review, [params.status, JSON.stringify(columns)]);

  // useMemo(() => ({
  //   columns: params.status === 'kelola' ? columns.kelola : columns.review,
  // }), [params.status, JSON.stringify(columns)]);
  // const memoColumnsSelected = useMemo(()=>{},[col])

  if (loading || loadingDefaultMisi) {
    return <AppPageLoading />;
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title={title}
        columns={columnsSelected}
        data={data?.map((item) => ({
          ...item,
          default_misi_id: defaultMisi?.misi_id,
        }))}
        actions={
          params.status === "kelola"
            ? [
                {
                  icon: "edit",
                  tooltip: "Edit Misi",
                  onClick: (event, data) => {
                    setAddDialog({
                      open: true,
                      data,
                      mode: "Edit",
                    });
                  },
                },
                {
                  icon: "add",
                  tooltip: "Tambah Keterangan",
                  onClick: (event, data) => {
                    history.push(`/misi/edit/${data.uid}`);
                  },
                },
                {
                  icon: "delete",
                  tooltip: "Delete Misi",
                  onClick: async (event, data) => {
                    await firestore.doc(`misi/${data.uid}`).delete();

                    enqueueSnackbar("Misi Berhasil Di Hapus", {
                      variant: "error",
                    });
                  },
                },
              ]
            : params.status === "review"
            ? [
                {
                  tooltip: "Detail",
                  icon: () => <VisibilityIcon />,
                  onClick: (event, data) => {
                    setDialog({
                      open: true,
                      data,
                    });
                  },
                },
              ]
            : []
        }
        localization={{
          body: {
            emptyDataSourceMessage: `Tidak Ada ${title}`,
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}
      />

      {params.status === "kelola" && (
        <>
          <Fab
            className={classes.fab1}
            color="primary"
            onClick={() => {
              setAddDialog({
                open: true,
                mode: "Tambah",
              });
            }}
          >
            <AddIcon />
          </Fab>

          <AddMisi
            {...addDialog}
            handleClose={() => {
              setAddDialog({
                open: false,
                mode: "",
              });
            }}
          />
        </>
      )}

      <Dialog
        dialog={dialog}
        handleClose={() => {
          setDialog((dialog) => {
            return {
              ...dialog,
              open: false,
            };
          });
        }}
      />
    </>
  );
}

export default Tabel;
