import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// utils
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { firestore, Arr } from '../../../components/FirebaseProvider';
import useStyles from './styles';
import RpFormat, {Numberformat} from '../../../components/NumberFormat';

function AddDialog({ dialog: { mode, open, data }, handleClose}) {

    const classes = useStyles();

    const [form, setForm] = useState({
        jobtitle: '',
        jobdesc: '',
        status: false

    })

    const [error, setError] = useState({
        jobtitle: '',
        jobdesc: '',
        status: ''
    })
    useEffect(() => {
        const defaultData = {
            jobtitle: '',
            jobdesc: '',
            status: false
        }

        const defaultError = {
            jobtitle: '',
            jobdesc: '',
            status: ''
        }

        if (mode === 'Tambah') {
            setForm(defaultData)
            setError(defaultError)
        } else if (mode === 'Edit') {
            setForm(data)
            setError(defaultError)
        }
    }, [data, mode])



    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(form => ({
            ...form,
            [name]: value
        }))

        setError(error => ({
            ...error,
            [name]: ''
        }))
    }

    const validate = async () => {
        const newError = { ...error };
        const { jobtitle, jobdesc} = form;

        if (!jobtitle) {
            newError.jobtitle = 'Job Title Wajib Diisi';
        }

        if (!jobdesc) {
            newError.jobdesc = 'Job Description Wajib Diisi';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();
        console.log(findError)


        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            const promoCol = firestore.collection(`jobtrend`);
            try {
                if (mode === 'Tambah') {
                    await promoCol.add({
                        jobtitle: form.jobtitle,
                        jobdesc: form.jobdesc,
                        status: form.status,
                        dibuat_pada: Arr.serverTimestamp()
                    });
                    handleClose()
                    enqueueSnackbar('JobTrend berhasil ditambahkan', { variant: "success" })
                }else if (mode === 'Edit') {
                    const { uid, tableData, ...restForm } = form;
                    await promoCol.doc(uid).update({
                        ...restForm, diperbarui_pada: Arr.serverTimestamp()
                    })
                    handleClose()
                    enqueueSnackbar('Job Trend Berhasil Diperbarui', { variant: "success" })
                }
            } catch (e) {
                const newError = {};

                newError.jobtitle = e.message;

                setError(newError);
            }
            setSubmitting(false)
        }
    }

    return <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick={isSubmitting}
        disableEscapeKeyDown={isSubmitting}
        open={open}
    >
        <DialogTitle>{mode} JobTrend</DialogTitle>
        <DialogContent dividers>
            <form id="create-admin-form" onSubmit={handleSubmit}>
                <TextField
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="jobtitle"
                    name="jobtitle"
                    label="Job Title"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // InputProps={{
                    //     inputComponent: Numberformat
                    // }}
                    fullWidth
                    autoComplete="off"
                    value={form.jobtitle}
                    onChange={handleChange}
                    helperText={error.jobtitle}
                    error={error.jobtitle ? true : false}
                />
                <TextareaAutosize
                    className={classes.marginTextfield}
                    disabled={isSubmitting}
                    id="jobdesc"
                    name="jobdesc"
                    aria-label="Job Description"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // InputProps={{
                    //     inputComponent: RpFormat,
                    // }}
                    placeholder="Job Requirements, Qualifications, Responsibilities & Activities"
                    style={{width: 400}}
                    minRows={10}
                    autoComplete="off"
                    value={form.jobdesc}
                    onChange={handleChange}
                    helperText={error.jobdesc}
                    error={error.jobdesc ? true : false}
                />
                <FormControl style={{ width: '100%' }}>
                    <InputLabel>Status Jobtrend</InputLabel>
                    <Select
                        className={classes.marginTextfield}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="status"
                        fullWidth
                        value={form.status}
                        onChange={handleChange}
                        disabled={isSubmitting}
                    >
                        <MenuItem value={true}>Aktif</MenuItem>
                        <MenuItem value={false}>Tidak Aktif</MenuItem>
                    </Select>
                </FormControl>
            </form>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={isSubmitting}
                onClick={handleClose}
            >Batal</Button>
            <Button
                form="create-admin-form"
                disabled={isSubmitting}
                type="submit"
                color="primary">Simpan</Button>
        </DialogActions>
    </Dialog >

}

AddDialog.propTypes = {
    dialog: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withRouter(AddDialog);
