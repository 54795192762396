import React, { useEffect, useState } from 'react';

import { firestore } from '../../../components/FirebaseProvider';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';

// Styles
import useStyles from './styles';

import MaterialTable from '@material-table/core';
import AppPageLoading from '../../../components/AppPageLoading';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import Dialog from './dialog';
import Fab from '@material-ui/core/Fab';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
//import { delimiters } from '../../../utils/formatter-rupiah';
import getTableTitle from '../../../utils/getTableTitle';

function Jobtrend() {

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const queryPromo = firestore.collection('jobtrend');
    const [snapshot, loading] = useCollection(queryPromo);

    const [dialog, setDialog] = useState({
        open: false,
        mode: '',
        data: []
    })

    const [table, setTable] = useState({
        columns: [
            {
                title: getTableTitle('Job Title'), field: 'jobtitle',
                render: rowData =>
                    <>
                        {
                            <span>{rowData.jobtitle}</span>
                        }
                    </>
            },
            {
                title: getTableTitle('Job Description'), field: 'jobdesc',
                render: rowData =>
                    <>
                        {
                            <span>{rowData.jobdesc}</span>
                        }
                    </>
            },
            {
                title: getTableTitle('Status'), field: 'status',
                render: rowData =>
                    <>
                        {
                            rowData?.status === true ?
                                <Tooltip title="Promo Aktif" arrow>
                                    <AttachMoneyIcon style={{ color: '#34cd2b' }} />
                                </Tooltip>
                                :
                                <Tooltip title="Promo Tidak Aktif" arrow>
                                    <MoneyOffIcon color="error" />
                                </Tooltip>

                        }
                    </>
            },
        ],
        data: [
        ],
    });

    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            setTable(table => {
                return {
                    ...table,
                    data: snapshot.docs.map(doc => {
                        return {
                            uid: doc.id,
                            ...doc.data()
                        }
                    })
                }
            })
        } else {
            setTable(table => {
                return {
                    ...table,
                    data: []
                }
            })
        }
    }, [snapshot]);

    if (loading) {
        return <AppPageLoading />
    }

    return (
        <>

            <Paper square>
                <MaterialTable
                    options={{ thirdSortClick: false }}
                    title="Job Trend Data"
                    columns={table.columns}
                    data={table.data}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, data) => {
                                setDialog({
                                    mode: 'Edit',
                                    data,
                                    open: true
                                })
                            }
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Hapus',
                            onClick: (event, data) => {
                                firestore.doc(`jobtrend/${data.uid}`).delete()
                                enqueueSnackbar('JobTrend Berhasil Dihapus', { variant: "success" })
                            }
                        },
                    ]}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Tidak Ada Data',
                            addTooltip: 'Tambah',
                            editTooltip: 'Ubah',
                            deleteTooltip: 'Hapus',
                            editRow: {
                                deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                                cancelTooltip: 'Batal',
                                saveTooltip: 'Ya'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Cari',
                            searchPlaceholder: 'Cari Data'
                        },
                        header: {
                            actions: 'Tindakan'
                        },
                        pagination: {
                            labelRowsSelect: 'Baris',
                            labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
                            firstTooltip: 'Halaman Pertama',
                            previousTooltip: 'Halaman Sebelumnya',
                            nextTooltip: 'Halaman Selanjutnya',
                            lastTooltip: 'Halaman Terakhir'
                        }
                    }}
                />
                <Fab
                    className={classes.fab}
                    color="primary"
                    onClick={() => {
                        setDialog({ mode: 'Tambah', open: true, data: {} });
                    }}
                >
                    <AddOutlinedIcon />
                </Fab>
                <Dialog
                    dialog={dialog}
                    handleClose={() => {
                        setDialog({ mode: 'Tambah', open: false, data: {} });
                    }}
                />
            </Paper>
        </>
    )
}

export default Jobtrend;