import React from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";

// const data = [
//     { name: "Group A", value: 400 },
//     { name: "Group B", value: 300 },
//     { name: "Group C", value: 300 },
//     { name: "Group D", value: 200 }
// ];

// const COLORS = ["#a7ffeb", "#FFFF00", "#ffd180", "#b6e3ff", '#ffbcaf', '#ccff90', '#84ffff'];
// const COLORS = ["#d63384", "#D0ED57", "#FFB836", "#26A4FF", '#dc3545', '#56CDAD', '#7B61FF'];
const COLORS = ["#FF8297", "#ffc107", "#fd7e14", "#26A4FF", '#FF6550', '#56CDAD', '#6f42c1'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="black"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function renderLegendText(value, entry, index) {
    return <span>{value} ({entry.payload?.value})</span>
}

export default function HarapanChart({ data }) {

    return (
        <PieChart width={250} height={350}>
            <Pie
                data={data}
                cx={125}
                cy={125}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Legend verticalAlign="bottom" align="left" width={285} height={100} formatter={renderLegendText} />
        </PieChart>
    );
}
