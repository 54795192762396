import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useSnackbar } from "notistack";
import { firestore } from "../../../components/FirebaseProvider";
import array_chunk from "lodash/chunk";

import Papa from "papaparse";

function MyDropzone() {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const accept = [
    ".csv",
    // '.xls',
    // '.xlsx'
  ];

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      // acceptedFiles((file) => {
      // const reader = new FileReader()

      // reader.onabort = () => setError('Pembacaan File Dibatalkan')
      // reader.onerror = () => setError('Pembacaan File Gagal')
      // reader.onload = () => {
      // Do whatever you want with the file contents
      // setLoading(true);
      // try{
      //const binaryStr = reader.result
      // console.log('%c OKE SIP GITU AJA', 'font-family: arial; font-weight: 900; font-size: 35px;color: limegreen; -webkit-text-stroke: 2px black;')

      //         enqueueSnackbar('Data Berhasil Diupload',{variant: 'success'});
      //         setLoading(false)
      //     }catch (e){
      //         console.log(e)
      //     }
      // }
      // reader.readAsArrayBuffer(file)
      // })

      const file = acceptedFiles[0];

      if (!file) {
        return null;
      }
      Papa.parse(file, {
        dynamicTyping: true,
        header: false,
        complete: async function (results, file) {
          console.log(results);
          if (results.data.length > 0) {
            if (
              results.data[0][0] === "code" &&
              results.data[0][1] === "nilai"
            ) {
              const chunk = array_chunk(results.data, 500);
              await Promise.all(
                chunk.map(async (currentData) => {
                  let writeBatch = firestore.batch();
                  currentData.forEach((voucher) => {
                    if (voucher[0] !== "code" && voucher[1] !== "nilai") {
                      let documentRef = firestore
                        .collection("voucher")
                        .doc(voucher[0]);
                      writeBatch.set(documentRef, {
                        code: voucher[0],
                        nilai: voucher[1],
                      });
                    }
                  });
                  await writeBatch.commit();
                })
              );
              enqueueSnackbar("Data Berhasil Diimpor", { variant: "success" });
            }
          }
        },
      });
    },
    [enqueueSnackbar]
  );

  const onDropRejected = useCallback(
    (rejected) => {
      if (!accept.includes(rejected[0].type)) {
        // console.log('%c HEE YANG BENER!', 'font-family: arial; font-weight: 900; font-size: 35px;color: red; -webkit-text-stroke: 2px black;')
        setError(`Tipe Tile Tidak Didukung (${rejected[0].type}) `);
        enqueueSnackbar("Tipe Tile Tidak Didukung", { variant: "error" });
      }
    },
    [accept, enqueueSnackbar]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    disabled: loading,
  });

  return (
    <div
      {...getRootProps()}
      style={{
        width: "100%",
        cursor: "pointer",
        padding: 15,
        backgroundColor: "#43a047",
        color: "white",
      }}
    >
      <input {...getInputProps()} />
      <Grid container spacing={2}>
        <Grid item>
          <InfoOutlinedIcon fontSize="small" />
        </Grid>
        <Grid item>
          <Typography variant="body2" align="left">
            {" "}
            Klik Disini Untuk Impor Data Dari File CSV
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default MyDropzone;
