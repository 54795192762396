import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import Textfield from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from './style';
import { useFirebase } from '../../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';
import AppPageLoading from '../../../components/AppPageLoading';
import { useSnackbar } from 'notistack';


function TemplateChat() {

    const classes = useStyles()
    
    const { firestore } = useFirebase();

    const { enqueueSnackbar } = useSnackbar()

    const chatCol = firestore.doc(`template_chat/template`)
    const [ Chat, loading ] = useDocument(chatCol)

    const [chatSet, setChatSet] = useState({
        chat: '',
        ebrosur: ''
    });

    const [submitC, setSubmitC] = useState(false);
    const [submitB, setSubmitB] = useState(false);

    const [error, setError] = useState({
        chat: '',
        ebrosur: '',
    })

    useEffect(() => {
        if(Chat){
            setChatSet(chatSet => ({
                ...chatSet,
                ...Chat.data()
            }))
        }
    }, [Chat])

    const handleChange = e => {
        const { name, value } = e.target;
        setChatSet(chatSet => ({
            ...chatSet,
            [name]: value
        }))

        setError(error => ({
            ...error,
            [name]: ''
        }))
    }

    const validateC = async () => {
        const newError = { ...error };
        const { chat } = chatSet;

        if (!chat) {
            newError.chat = 'Wajib Di Isi';
        }
        return newError
    }
    
    const validateB = async () => {
        const newError = { ...error };
        const { ebrosur } = chatSet;

        if (!ebrosur) {
            newError.ebrosur = 'Wajib Di Isi';
        }

        return newError
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validateC();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitC(true)
            try {
                await firestore.doc('template_chat/template').set({
                    chat: chatSet.chat
                }, {merge: true})

                enqueueSnackbar('Template Berhasil Diperbarui', {variant: 'success'})
            }
            catch (e) {
                const newError = {};

                newError.chat = e.message;

                setError(newError);

                enqueueSnackbar('Template Gagal Diperbarui', {variant: 'error'})
            }}
            
            setSubmitC(false)
        }
        
        const handleSubmitBrosur = async e => {
            e.preventDefault();
            const findError = await validateB();
    
            if (Object.values(findError).some(m => m !== "")) {
                setError(findError)
            } else {
                setSubmitB(true)
                try {
                    await firestore.doc('template_chat/template').set({
                        ebrosur: chatSet.ebrosur
                    }, {merge: true})

                    enqueueSnackbar('Template Berhasil Diperbarui', {variant: 'success'})
                }
                catch (e) {
                    const newError = {};
    
                    newError.chat = e.message;
    
                    setError(newError);

                    enqueueSnackbar('Template Gagal Diperbarui', {variant: 'error'})
                }}
                
                setSubmitB(false)
            }

            if (loading) {
                return <AppPageLoading />
            }

            const variabel = {
                nama : '{nama_siswa}',
                sekolah : '{nama_sekolah}'
            } 

    return(
        <>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                    <h2 className={classes.pageTitle}>Panduan</h2>
                    <ul>
                        <li><p className={classes.pageTitle}>Masukkan <b>{variabel.nama}</b> Untuk Menampilkan Nama Siswa ybs</p></li>
                        <li><p className={classes.pageTitle}>Masukkan <b>{variabel.sekolah}</b> Untuk Menampilkan Nama Sekolah ybs</p></li>
                        <li><p className={classes.pageTitle}>Jika Data Nama Siswa Tidak Ada Atau Bermasalah, <b>{variabel.nama}</b> Akan Ditampilkan Sebagai "Pengguna PakBudi"</p></li>
                    </ul>
                </Grid>
                    <Grid item sm={12} md={6}>
                        <h2 className={classes.pageTitle}>Contoh</h2>
                        <ul><li>
                        <p className={classes.pageTitle}>Halo {variabel.nama}, Saya Guru BK {variabel.sekolah}</p>
                        <p className={classes.pageTitle}>Akan Diubah Menjadi...</p>
                        <p className={classes.pageTitle}>Halo Tegar Babarunggul, Saya Guru BK SMAN 5 PURWOKERTO</p>
                        </li></ul>
                </Grid>
            </Grid>
            <br/>
            <h2 className={classes.pageTitle}>Template Pesan Whatsapp</h2>
            <Grid container spacing={1} justify="center" alignItems="center" alignContent="center">
                <Grid item xs={10}>
                    <Textfield
                        multiline
                        fullWidth
                        name="chat"
                        id="chat"
                        variant="outlined"
                        disabled={submitC}
                        onChange={handleChange}
                        value={chatSet.chat}
                        helperText={error.chat}
                        error={error.chat ? true : false}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={submitC}
                        onClick={handleSubmit}
                    >
                        Simpan
                    </Button>
                </Grid>
            </Grid>
            <h2 className={classes.pageTitle}>Template Share Ebrosur Whatsapp</h2>
            <Grid container spacing={1} justify="center" alignItems="center" alignContent="center">
                <Grid item xs={10}>
                    <Textfield
                        multiline
                        fullWidth
                        name="ebrosur"
                        id="ebrosur"
                        variant="outlined"
                        disabled={submitB}
                        onChange={handleChange}
                        value={chatSet.ebrosur}
                        helperText={error.ebrosur}
                        error={error.ebrosur ? true : false}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={submitB}
                        onClick={handleSubmitBrosur}
                    >
                        Simpan
                    </Button>
                </Grid>
            </Grid>
        </>
    )

}

export default TemplateChat