import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  firestore,
  FieldValue,
  functions,
} from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { Typography, Divider } from "@material-ui/core";
import format from "date-fns/format";
import { formatDate } from "../../../utils/formatter";

export default function DialogCoin({ dialog: { open, data }, handleClose }) {
  const [submitting, setSubmitting] = useState(false);

  const [invalidating, setInvalidating] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleValid = async () => {
    if (submitting || invalidating) {
      return null;
    }

    setSubmitting(true);
    try {
      const counterPesertaEventRef = firestore.doc("counters/peserta_event");

      const count = await firestore.runTransaction((transaction) => {
        return transaction.get(counterPesertaEventRef).then((doc) => {
          const count = doc.data().count + 1;
          transaction.update(counterPesertaEventRef, { count });
          return count;
        });
      });

      const strCount = `${count}`;
      let padLength = strCount.length;
      if (padLength < 4) {
        padLength = 4;
      }
      const padCount = strCount.padStart(padLength, "0");
      const pesertaId = `${data.lokasi_event.id}-${format(
        new Date(),
        "yy"
      )}-${padCount}`;

      let newData = {
        pesertaId,
        nama_lengkap: data.nama_lengkap,
        no_telepon: data.no_telepon,
        no_wa: data.no_wa,
        email: data.email,
        jenis_tryout: data.jenis_tryout,
        lokasi_event: data.lokasi_event,
        pakbudi_tryout_id: data.pakbudi_tryout_id ?? "",
        transaksi_event_id: data.id,
        created_at: FieldValue.serverTimestamp(),
        updated_at: FieldValue.serverTimestamp(),
      };

      if (data?.registrasi_lengkap) {
        let newAddData = {
          email_user: data?.email_user ?? "",
          asal_sekolah: data?.asal_sekolah ?? "",
          kelas: data?.kelas ?? "",
          jurusan: data?.jurusan ?? "",
          provinsi: data?.provinsi ?? {},
          kota: data?.kota ?? {},
          teman: data?.teman ?? "",
          tahu: data?.tahu ?? "",
          telepon_teman: data?.telepon_teman ?? "",
          registrasi_lengkap: true,
        };

        newData = { ...newData, ...newAddData };
      }

      await firestore
        .doc(`events/${data.event_id}/peserta_event/${pesertaId}`)
        .set(newData, { merge: true });

      await firestore.doc(`transaksi_event/${data.id}`).set(
        {
          status: "sukses",
          pesertaId,
          approved_at: FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

      const bonusRefEvent = functions.httpsCallable("bonusRefEvent");

      await bonusRefEvent({
        transaksi_id: data.id,
      });
      handleClose();
      enqueueSnackbar("Transaksi Telah Divalidasi", { variant: "success" });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Terjadi Kesalahan: " + e.message, { variant: "error" });
    }
    setSubmitting(false);
  };

  const handleRejected = async () => {
    if (invalidating || submitting) {
      return null;
    }
    setInvalidating(true);
    await firestore.doc(`transaksi_event/${data.id}`).set(
      {
        status: "rejected",
        rejected_at: FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
    handleClose();
    enqueueSnackbar("Transaksi Tidak Divalidasi", { variant: "error" });
    setInvalidating(false);
  };

  const handleClickView = () => {
    return window.open(data.photoURL);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown={submitting || invalidating}
      disableBackdropClick={submitting || invalidating}
    >
      <DialogTitle
        style={{ textAlign: "center", fontWeight: "bold" }}
        id="alert-dialog-title"
      >
        {"Review Transaksi Event"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        style={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid style={{ margin: "auto" }}>
          <Grid item>
            <img
              onClick={handleClickView}
              width="100%"
              src={data.bukti_pembayaran?.url_bukti}
              alt="Gambar Bukti Transaksi"
            />
            <br />
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <br />
              <Typography variant="body2">
                <b>Info Data Peserta</b>
              </Typography>
              <br />
              <Typography variant="caption">
                Nama :{" "}
                {data.nama_lengkap ? data.nama_lengkap : <i>Tidak Ada Nama</i>}
              </Typography>
              <br />
              <Typography variant="caption">
                No.Hp:{" "}
                {data.no_telepon ? data.no_telepon : <i>Tidak Ada No.Telp</i>}
              </Typography>
              <br />
              <Typography variant="caption">
                WA: {data.no_wa ? data.no_wa : <i>Tidak Ada WA</i>}
              </Typography>
              <br />
              <Typography variant="caption">
                E-mail: {data.email ? data.email : <i>Tidak Ada E-mail</i>}
              </Typography>
              <br />
              <br />
            </Grid>
            <Grid item xs={6}>
              <br />
              <Typography variant="body2">
                <b>Info Data Konfirmasi Pembayaran</b>
              </Typography>
              <br />
              <Typography variant="caption">
                Transfer Atas Nama:{" "}
                {data?.bukti_pembayaran?.atas_nama ?? <i>Tidak Ada Data</i>}
              </Typography>
              <br />
              <Typography variant="caption">
                Rek Bank Asal:{" "}
                {data?.bukti_pembayaran?.nama_bank ?? <i>Tidak Ada Data</i>}
              </Typography>
              <br />
              <Typography variant="caption">
                Tanggal Transfer:{" "}
                {data?.bukti_pembayaran?.waktu_pembayaran ? (
                  formatDate(data?.bukti_pembayaran?.waktu_pembayaran)
                ) : (
                  <i>Tidak Ada Data</i>
                )}
              </Typography>
              <br />
              <br />
            </Grid>
          </Grid>
          <Divider />
          <DialogActions style={{ padding: 0, marginBottom: 20 }}>
            <Button
              disabled={invalidating || submitting}
              onClick={handleRejected}
              fullWidth
              size="large"
              variant="contained"
              style={{ backgroundColor: "#c0392b", color: "white" }}
            >
              {invalidating ? "Invalidating..." : "Invalid"}
            </Button>
            <Button
              disabled={submitting || invalidating}
              onClick={handleValid}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
            >
              {submitting ? "Validating..." : "Valid"}
            </Button>
          </DialogActions>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
