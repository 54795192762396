// React
import React from "react";

// Image

// Material UI
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

// Styles
import useStyles from "./styles/index";

// Firebase
import { useFirebase } from "../../components/FirebaseProvider";

// React Router Dom
import { Redirect, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

function Restricted() {
  const classes = useStyles();
  const history = useHistory();

  // Firebase
  const { auth, user } = useFirebase();
  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.content}>
        <Typography className={classes.text2}>Ada Masalah !</Typography>
        <Typography className={classes.text3}>Periksa koneksi internet anda lalu reload halaman. Pastikan anda login menggunakan akun admin</Typography>
        {/* <ul>
          <li>
            <Typography className={classes.text4}>
              Koneksi Internet Anda Bermasalah
            </Typography>
          </li>
          <li>
            <Typography className={classes.text4}>
              Atau Akun Anda Bukan Akun Admin
            </Typography>
          </li>
        </ul>
        <Typography className={classes.text3}>Coba Untuk...</Typography>
        <ul>
          <li>
            <Typography className={classes.text4}>
              Periksa Koneksi Internet Anda
            </Typography>
          </li>
          <li>
            <Typography className={classes.text4}>
              Memuat Ulang Halaman
            </Typography>
          </li>
          <li>
            <Typography className={classes.text4}>
              Masuk Dengan Akun Admin
            </Typography>
          </li>
        </ul>
        <Typography className={classes.text5}>
          Tekan Keluar Untuk Beralih Akun, Tekan Muat Ulang Untuk Memuat Halaman
        </Typography> */}
        <Grid container alignItems="center" justify="center">
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.refreshBtn}
              fullWidth
              disableElevation
              // onClick={() => {
              //   window.location.push();
              // }}
              onClick={() => {
                history.push("/login");
              }}
            >
              Reload
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.logoutBtn}
              onClick={() => {
                auth.signOut();
              }}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default Restricted;
