import React, { useEffect, useState } from "react";

import { firestore } from "../../../components/FirebaseProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

import MaterialTable from "@material-table/core";
import AppPageLoading from "../../../components/AppPageLoading";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";

import { formatDate } from "../../../utils/formatter";
import MyDropzone from "./uploadFile";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DownloadTemplate from "./downloadTemplate";
import getTableTitle from "../../../utils/getTableTitle";

function Voucher() {
  const params = useParams();

  let queryVou = null;
  if (params.category === "all") {
    queryVou = firestore.collection("voucher");
  } else {
    queryVou = firestore.collection("voucher_umum");
  }
  const [snapshot, loading] = useCollection(queryVou);

  const { enqueueSnackbar } = useSnackbar();

  const [tableUmum, setTableUmum] = useState({
    columns: [
      { title: getTableTitle("ID"), field: "code", export: true },
      { title: getTableTitle("Nilai Voucher"), field: "nilai" },
      {
        title: getTableTitle("Aktif"),
        field: "use_status",
        lookup: {
          true: (
            <CheckCircleIcon style={{ color: "#34cd2b" }} variant="subtitle2" />
          ),
          false: <CancelIcon color="error" />,
        },
        render: (rowData) => (
          <>
            {rowData && rowData.use_status === true ? (
              <CheckCircleIcon
                style={{ color: "#34cd2b" }}
                variant="subtitle2"
              />
            ) : (
              <CancelIcon color="error" />
            )}
          </>
        ),
      },
      { title: getTableTitle("Jumlah Pengguna"), field: "used_by", editable: "never" },
    ],
    data: [],
  });

  const [table, setTable] = useState({
    columns: [
      { title: getTableTitle("ID"), field: "code", export: true },
      { title: getTableTitle("Nilai Voucher"), field: "nilai" },
      {
        title: getTableTitle("Diubah"),
        field: "updatedAt",
        editable: "never",

        render: (rowData) => (
          <>
            {rowData && rowData.updatedAt !== undefined ? (
              <span>{formatDate(rowData.updatedAt)}</span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Digunakan"),
        field: "use_status",
        editable: "never",
        render: (rowData) => (
          <>
            {rowData && rowData.use_status === true ? (
              <CheckCircleIcon
                style={{ color: "#34cd2b" }}
                variant="subtitle2"
              />
            ) : (
              <CancelIcon color="error" />
            )}
          </>
        ),
      },
      { title: getTableTitle("ID Pengguna"), field: "used_by", editable: "never" },
    ],
    data: [],
  });

  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      if (params.category === "all") {
        setTable((table) => {
          return {
            ...table,
            data: snapshot.docs.map((doc) => {
              return {
                uid: doc.id,
                ...doc.data(),
              };
            }),
          };
        });
      } else {
        setTableUmum((table) => {
          return {
            ...table,
            data: snapshot.docs.map((doc) => {
              return {
                uid: doc.id,
                ...doc.data(),
              };
            }),
          };
        });
      }
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [params.category, snapshot]);

  // const validate = async (newData, oldData) => {
  //     const newError = {};
  //     const { code: newCode, nilai: newNilai } = newData;
  //     const { code: oldCode, nilai: oldNilai } = oldData;

  //     if (!newCode) {
  //         newError.code = 'code wajib di isi';

  //     } else if (newCode !== oldCode) {

  //         const querySanpshot = await firestore.collection('voucher').where('code', '==', newCode).get();

  //         if (!querySanpshot.empty) {
  //             newError.code = 'Kode Tersebut Sudah Dibuat';
  //         }
  //     }

  //     if (!newNilai) {
  //         newError.nilai = 'Nilai Wajib Diisi';
  //     }
  // }

  if (loading) {
    return <AppPageLoading />;
  }

  return (
    <>
      <Paper square>
        {params.category === "all" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MyDropzone />
            <DownloadTemplate />
          </div>
        ) : null}
        <MaterialTable
          options={{ thirdSortClick: false }}
          title={params.category === "all" ? "Voucher" : "Voucher Umum"}
          columns={
            params.category === "all" ? table.columns : tableUmum.columns
          }
          data={params.category === "all" ? table.data : tableUmum.data}
          components={{
            Container: (props) => <div {...props} elevation={0} />,
          }}
          editable={{
            onRowDelete: async (oldData) => {
              if (params.category === "all") {
                await firestore.doc(`voucher/${oldData.uid}`).delete();
              } else {
                await firestore.doc(`voucher_umum/${oldData.uid}`).delete();
              }
              enqueueSnackbar("Voucher Berhasil Dihapus", {
                variant: "success",
              });
            },

            onRowAdd: async (newData) => {
              try {
                if (!newData.code || !newData.nilai) {
                  enqueueSnackbar("Data Voucher Harus Lengkap", {
                    variant: "error",
                  });

                  throw new Error("Data Voucher Harus Lengkap");
                }
                if (params.category === "all") {
                  const newColData = {
                    code: newData.code,
                    nilai: newData.nilai,
                    updatedAt: Date.now(),
                    use_status: false,
                    used_by: "",
                  };
                  await firestore.collection("voucher").add(newColData);
                } else {
                  const newColData = {
                    code: newData.code,
                    nilai: newData.nilai,
                    updatedAt: Date.now(),
                    use_status: JSON.parse(newData.use_status),
                    used_by: 0,
                  };
                  await firestore.collection("voucher_umum").add(newColData);
                }

                enqueueSnackbar("Voucher Berhasil Ditambahkan", {
                  variant: "success",
                });
              } catch (e) {
                console.log(e.message);
              }
            },

            onRowUpdate: async (newData, oldData) => {
              // const findErrors = await validate(newData, oldData);
              // const errorMessages = Object.values(findErrors);

              // if (errorMessages.some(m => m !== "")) {
              //     errorMessages.forEach((message) => {
              //         if (message)
              //             enqueueSnackbar(message, { variant: 'error' })
              //             console.log(message)
              //     })
              //     throw new Error(errorMessages[0]);
              // }
              if (params.category === "all") {
                const newColData = {
                  code: newData.code,
                  nilai: newData.nilai,
                  updatedAt: Date.now(),
                };
                await firestore
                  .doc(`voucher/${oldData.uid}`)
                  .set(newColData, { merge: true });
              } else {
                const newColData = {
                  code: newData.code,
                  nilai: newData.nilai,
                  updatedAt: Date.now(),
                  use_status: JSON.parse(newData.use_status),
                };
                await firestore
                  .doc(`voucher_umum/${oldData.uid}`)
                  .set(newColData, { merge: true });
              }

              enqueueSnackbar("Voucher Berhasil Diubah", {
                variant: "success",
              });
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak Ada Data",
              addTooltip: "Tambah",
              editTooltip: "Ubah",
              deleteTooltip: "Hapus",
              editRow: {
                deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
                cancelTooltip: "Batal",
                saveTooltip: "Ya",
              },
            },
            toolbar: {
              searchTooltip: "Cari",
              searchPlaceholder: "Cari Data",
            },
            header: {
              actions: "Tindakan",
            },
            pagination: {
              labelRowsSelect: "Baris",
              labelDisplayedRows: " {from}-{to} Dari {count} Baris",
              firstTooltip: "Halaman Pertama",
              previousTooltip: "Halaman Sebelumnya",
              nextTooltip: "Halaman Selanjutnya",
              lastTooltip: "Halaman Terakhir",
            },
          }}
        />
      </Paper>
    </>
  );
}

export default Voucher;
