import React, { useState, useEffect } from 'react';

// material-ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

// utils
import { useSnackbar } from 'notistack';
import { firestore, Arr } from '../../../components/FirebaseProvider';
import PropTypes from 'prop-types';



function AddDialogC({  open, items, handleClose, mode, data }) {

    const [form, setForm] = useState({
        title: '',
        id: '',
        pinNews : {},
    })

    const [error, setError] = useState({
        title: '',
        id: '',
    })

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const [ newsOptions, setNewsOptions] = useState([]);

    const [newsLoading, setNewsLoading] = useState(false);

    useEffect(()=>{
        if(mode==="Edit"){
            setForm(data);
        }else{
            setForm({id: '', title: '', pinNews: {}})
        }
            //console.log(mode);
    },[mode,data])

    useEffect(()=>{
        if(mode==="Edit"){
        const getNewsByKategori = async ()=>{
            setNewsLoading(true);
            try{
                const newsSnapshot = await firestore.collection('news').where("kategori.id","==", data.id).where("status","==","published").get();
                
                if(!newsSnapshot.empty){
                    setNewsOptions(newsSnapshot.docs.map(doc=>{
                        const news = doc.data();
                        return {id: doc.id, title: news.title, createdAt: news.publishedAt, thumbnailURL: news.thumbnailURL} 
                    }))
                }
                setNewsLoading(false);
            }catch(e){
                console.log(e.message);
                setNewsLoading(false);
            }
        }
        getNewsByKategori();
    }
    },[mode, data.id])

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }


    const validate = async () => {
        const newError = { ...error };
        const { title, id } = form;


        if (!title) {
            newError.title = 'Masukkan Judul';
        }

        if (!id){
            newError.id = 'Masukkan ID'
        }
        else{
            if(mode==="Tambah"){
            const findId = items.find(item=>item.id===id);
                if (findId){
                    newError.id = 'ID Sudah Digunakan'
                }
            }
        }
        if(!form.pinNews){
            newError.pinNews = 'Pilih Berita Yang Akan Di Pin'
        }

        return newError;
    }

    const handleAutocompleteChange = name=>( e ,v)=>{
      
        handleChange({target:{value:v,name}})
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const catCol = firestore.doc(`news/kategori`);
                let newData = {...form}
                if(mode==="Tambah"){
                    await catCol.set({
                        items: Arr.arrayUnion({id: form.id, title: form.title, createdAt: Date.now()}),
                        status: 'kategori',
                        kategori: 'kategori'
                    },{merge: true});
                }
                else if(mode==="Edit"){
                    newData.updatedAt= Date.now();
                    delete newData.tableData
                    await catCol.set({
                        items: items.map(item=>{
                            if(item.id===form.id){
                                return newData
                            }
                            delete item.tableData
                            return item
                        })
                    },{merge: true});
                }
              
            
                enqueueSnackbar('Kategori Berhasil Ditambahkan', { variant: "success" })
                handleClose();
                
            } catch (e) {
                const newError = {};
                console.log(e)

                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }

    //console.log(form)

    let btnText = "Simpan"

    if(mode==="Tambah"){
        btnText = "Buat"
    }

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{mode} Kategori</DialogTitle>
            <DialogContent dividers>
                <form id="create-admin-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting}
                                autoComplete="off"
                                id="title"
                                name="title"
                                label="Judul Kategori"
                                fullWidth
                                variant="outlined"
                                value={form.title}
                                onChange={handleChange}
                                helperText={error.title}
                                error={error.title ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting || mode==="Edit"}
                                autoComplete="off"
                                id="id"
                                name="id"
                                label="ID Kategori"
                                fullWidth
                                variant="outlined"
                                value={form.id}
                                onChange={handleChange}
                                helperText={error.id}
                                error={error.id ? true : false}
                            />
                        </Grid>
                            {
                                mode === "Edit" &&
                                <>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            id="pinNewsKategori"
                                            name="pinNews"
                                            options={newsOptions}
                                            getOptionLabel={option => option.title}
                                            loading={newsLoading}
                                            onChange={handleAutocompleteChange('pinNews')}
                                            disableClearable
                                            value={form.value}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    disabled={isSubmitting}
                                                    label="Pin Berita"
                                                    variant="outlined"
                                                    fullWidth
                                                    helperText={error.pinNews}
                                                    error={error.pinNews ? true : false}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            }
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    form="create-admin-form"
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                >
                    {btnText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddDialogC.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddDialogC;
