import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import WorkIcon from '@material-ui/icons/Work';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import GameIcon from '@material-ui/icons/SportsEsportsOutlined';
import useStyles from './styles';
import TopBar from '../../components/TopBar2';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import LocalLibraryIcon from '@material-ui/icons/LocalLibraryOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './home';
import Akun from './akun';
import News from './news';
import Tryout from './tryout';
import Data from './data';
import Voucher from './voucher';
import Promo from './Promo Coin';
import Jobtrend from './jobtrend';
import transaksi from './transaksi';
import Pencairan from './Pencairan Fee';
import PendaftarITTP from './pendaftarITTP';
import Esport from './esport';
import { useFirebase } from '../../components/FirebaseProvider';
import TemplateChat from './templateChat';
import Misi from './misi';
import Broadcast from './broadcast';
import Event from './event';
import TransaksiEvent from './transaksi-event';

function Private(props) {
    const classes = useStyles();
    const { auth, role } = useFirebase();
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false)
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Dialog Box
    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };


    const handleLogout = (e) => {
        auth.signOut();
    }

    let activerole = "admin";
    if (role && role.data() && role.data().akses === "cs") {
        activerole = "cs"
    }


    return (
        <div className={classes.root}>

            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <Tooltip title="Luaskan Menu" placement="right">
                        <IconButton
                            edge="start"
                            aria-label="Open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography component="h1" variant="h6" noWrap className={classes.title}>
                        <Switch>

                            <Route path="/akun" children="Pengelola Akun" />
                            <Route path="/affiliate" children="Affiliate" />
                            <Route path="/order" children="Order" />
                            <Route path="/news" children="Pengelola Berita" />
                            <Route path="/tryout" children="Pengelola Tryout" />
                            <Route path="/data/sekolah" children="Pengelola Data Sekolah" />
                            <Route path="/data/jurusan-kuliah" children="Pengelola Data Jurusan Universitas" />
                            <Route path="/transaksi" children="Pengelola Transaksi" />
                            <Route path="/misi" children="Pengelola Misi" />
                            <Route path="/voucher" children="Pengelola Voucher" />
                            <Route path="/promocoin" children="Pengelola Promo" />
                            <Route path="/pencairanfee" children="Pengelola Pencairan Fee" />
                            <Route path="/pendaftarITTP" children="Pendaftar ITTP" />
                            <Route path="/templateChat" children="Template Chat" />
                            <Route path="/broadcast" children="Broadcast Notification" />
                            <Route path="/esport" children="Pengelola E-Sport" />
                            <Route path="/event" children="Pengelola Event" />
                            <Route path="/transaksi-event" children="Transaksi Event" />
                            <Route path="/jobrend" children="Jobtrend" />
                            <Route path="/home" children="Dashboard" />
                            <Route children="Super Admin" />

                        </Switch>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <TopBar />
                    <Tooltip title="Sempitkan Menu" placement="right">
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <Divider />
                <List style={{ maxHeight: "calc(100vh - 65px)" }}>
                    {activerole === "admin" &&
                        <>
                            <Route path="/home" children={({ match, history }) => {

                                return (
                                        <ListItem button selected={match ? true : false} onClick={() => history.push('/')}>
                                            <ListItemIcon>
                                                <HomeOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Beranda" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/akun" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/akun')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <PeopleOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Akun" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/news" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/news')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <LibraryBooksOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Berita" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/tryout" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/tryout')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <LocalLibraryIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tryout" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/data" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/data')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <FolderOpenOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Data" />
                                        </ListItem>
                                )
                            }} />
                            {/* <Route path="/transaksi" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/transaksi')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <AccountBalanceWalletOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Transaksi" />
                                        </ListItem>
                                )
                            }} /> */}
                            <Route path="/misi" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/misi')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <AssignmentTurnedInIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Misi" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/jobtrend" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/jobtrend')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <WorkIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="JobTrend" />
                                        </ListItem>
                                )
                            }} />
                            {/* <Route path="/esport" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/esport')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <GameIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="E-Sport" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/event" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/event')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <CalendarTodayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Event" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/transaksi-event" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/transaksi-event')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <EventAvailableIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Transaksi Event" />
                                        </ListItem>
                                )
                            }} /> */}
                            <Route path="/broadcast" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/broadcast')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <SettingsInputAntennaIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Broadcast Notif" />
                                        </ListItem>
                                )
                            }} />
                        </>
                    }

                    {activerole === "admin" &&
                        <>
                            <Route path="/pencairanfee" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/pencairanfee')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <LocalAtmOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Pencairan Fee" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/voucher" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/voucher')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <ConfirmationNumberOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Voucher" />
                                        </ListItem>
                                )
                            }} />
                            <Route path="/promocoin" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/promocoin')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <MonetizationOnOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Promo Coin" />
                                        </ListItem>
                                )
                            }} />
                            {/* <Route path="/pendaftarITTP" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/pendaftarITTP')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <SchoolOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Pendaftar ITTP" />
                                        </ListItem>
                                )
                            }} /> 
                            <Route path="/templateChat" children={({ match, history }) => {

                                return (
                                        <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() => {
                                                history.push('/templateChat')
                                            }}
                                        >
                                            <ListItemIcon>
                                                <ForumOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Template Chat" />
                                        </ListItem>
                                )
                            }} /> */}
                        </>}
                        <ListItem
                            button
                            onClick={handleClickOpen}
                            className={classes.logoutBtn}
                        >
                            <ListItemIcon>
                                <ExitToAppOutlinedIcon style={{ color: "#FFF" }} />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                </List>

            </Drawer>
            <main className={classes.content} id="main-content">
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Switch>
                        <Route component={Home} path="/home" />
                        <Route component={News} path="/news" />
                        <Route component={Tryout} path="/tryout" />
                        <Route component={Akun} path="/akun" />
                        <Route component={Data} path="/data" />
                        <Route component={transaksi} path="/transaksi" />
                        <Route component={Voucher} path="/voucher" />
                        <Route component={Promo} path="/promocoin" />
                        <Route component={Jobtrend} path="/jobtrend" />
                        <Route component={Pencairan} path="/pencairanfee" />
                        <Route component={PendaftarITTP} path="/pendaftarITTP" />
                        <Route component={TemplateChat} path="/templateChat" />
                        <Route component={Misi} path="/misi" />
                        <Route component={Broadcast} path="/broadcast" />
                        <Route component={Esport} path="/esport" />
                        <Route component={Event} path="/event" />
                        <Route component={TransaksiEvent} path="/transaksi-event" />
                        <Redirect to="/home" />
                    </Switch>
                </Container>
            </main>

            {/* Dialog Box Logout */}

            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.dialog}
            >
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
                        </Grid>
                    </Grid>
                    <DialogTitle id="alert-dialog-slide-title"><Typography style={{ textAlign: 'center' }} >Apakah Anda Yakin?</Typography></DialogTitle>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup
                        fullWidth
                        variant="text"
                    >
                        <Button
                            onClick={handleClose}
                            color="primary"
                        >
                            Tidak
                        </Button>
                        <Button
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div >
    );
}


export default Private;