import React from 'react';

// Styles
import './App.css';
import theme from './theme';

// MUI
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// Component
import Login from './pages/login';
import Private from './pages/private';
import NotFound from './pages/404';
import Restricted from './pages/restricted';

// Utils
import PrivateRoute from './components/PrivateRoute';
import FirebaseProvider from './components/FirebaseProvider';
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';

function App() {

  const cusTheme = createMuiTheme(theme);

  return (
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={cusTheme}>
        <div className="App">
          <div className="Content">
            <SnackbarProvider maxSnack={20} autoHideDuration={3000}>
              <FirebaseProvider>
                <Router>
                  <Switch>
                    <PrivateRoute exact path="/" component={Private} />
                    <PrivateRoute path="/home" component={Private} />
                    <PrivateRoute path="/voucher" component={Private} />
                    <PrivateRoute path="/pencairanfee" component={Private} />
                    <PrivateRoute path="/promocoin" component={Private} />
                    <PrivateRoute path="/pendaftarITTP" component={Private} />
                    <PrivateRoute path="/akun" component={Private} />
                    <PrivateRoute path="/news" component={Private} />
                    <PrivateRoute path="/tryout" component={Private} />
                    <PrivateRoute path="/data" component={Private} />
                    <PrivateRoute path="/transaksi" component={Private} />
                    <PrivateRoute path="/templateChat" component={Private} />
                    <PrivateRoute path="/misi" component={Private} />
                    <PrivateRoute path="/broadcast" component={Private} />
                    <PrivateRoute path="/jobtrend" component={Private} />
                    <PrivateRoute path="/esport" component={Private} />
                    <PrivateRoute path="/event" component={Private} />
                    <PrivateRoute path="/transaksi-event" component={Private} />
                    <Route path="/login" component={Login} />
                    <Route path="/restricted" component={Restricted} />
                    <Route component={NotFound} />
                  </Switch>
                </Router>
              </FirebaseProvider>
            </SnackbarProvider>
          </div>
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App;