import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    tabContent: {
        marginBottom: 50,
    },

    fab1: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    fab2: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    title: {
        flex: '1 1 100%',
    },
    pageHeader: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: 'auto',
    },
    createBtn: {
        marginLeft: 10,
    },
    manageNews: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    editorWrap: {
        width: '90%',
        margin: 'auto',
    },
    formRow: {
        width: '100%',
        marginBottom: 30,
        marginTop: 30,
        borderRadius: 8,
        paddingBottom: 20,
        backgroundColor: '#fff',
    },
    textField: {
        width: '100%',
        backgroundColor: '#fff',
        marginBottom: 10,
    },
    radioCon: {
        padding: 20,
        marginBottom: 15,
    },
    editorBox: {
        backgroundColor: '#fff',
        padding: theme.spacing(0, 7, 5, 7),
        marginBottom: 30,
        borderRadius: 8,
    },

    toolbarBox: {
        padding: 20,
        backgroundColor: '#fff',
        border: 'none',
        borderRadius: 8,
        marginBottom: -7,
    },

    thumbMB: {
        marginBottom: 8,
    },

    thumbLayout: {
        borderRadius: 5,
        backgroundColor: '#fff',
        padding: 40,
    },

    thumbPLayout: {
        borderRadius: 5,
        borderStyle: 'dashed',
        border: '1.4px solid #888',
        backgroundColor: '#FAFAFA',
        marginBottom: 10
    },

    thumbPreview: {
        width: '100%',
    },

    btnAction: {
        border: '1px solid #30bcec',
        '&:hover': {
            border: '1px solid #30bcec',
        }
    },

    bannerpreview: {
        width: '95%',
        height: 300,
        borderRadius: 5,
        borderStyle: 'dashed',
        border: '2px solid #888',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    upload: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', 
        width: '100%', 
        height: '100%', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    urlInput: {
        width: '95%', 
        display: 'flex', 
        flexFlow: 'row', 
        margin: '20px auto', 
    },

    txt: {
        padding: 20, 
        fontWeight: '500', 
        fontSize: 20, 
        marginLeft: 10
    },

    Root: {
        height: 480
    },

    txtUrl: {
        width: '90%'
    },
    
    btnUrl: {
        marginLeft: 5,
        textTransform: 'capitalize'
    }

}))


export default useStyles;