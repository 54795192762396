// 
import React, { useState, useEffect } from 'react';

// material-ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// utils
import { useSnackbar } from 'notistack';
import { firestore, Arr } from '../../../components/FirebaseProvider';
import { Numberformat } from '../../../components/NumberFormat';
import isURL from 'validator/lib/isURL';
function AddDialogN({ open, handleClose, mode, data }) {

    const [form, setForm] = useState({
        judul: '',
        reward: '',
        status: 'non-active',
        url: '',
        order: ''
    })

    const [error, setError] = useState({
        judul: '',
        reward: '',
    })

    useEffect(() => {
        if (mode === "Edit") {
            setForm({
                judul: data.judul,
                reward: data.reward,
                status: data.status_misi,
                url: data.url,
                order: data.order?.toString?.() ?? ''
            });
        } else {
            setForm({
                judul: '',
                reward: '',
                status: 'non-active',
                url: ''
            })
        }
    }, [mode, data])

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    const validate = async () => {
        const newError = { ...error };
        const { judul, reward } = form;

        if (!judul) {
            newError.judul = 'Judul Wajib di Isi';
        }

        if (!reward) {
            newError.reward = 'Reward Wajib di Isi';
        }
        if (!form.url) {
            newError.url = 'URL Wajib di Isi'
        } else if (!isURL(form.url)) {
            newError.url = 'URL Tidak Valid'
        }

        if (!form.order) {
            newError.order = 'Urutan Wajib Di isi';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                if (mode === "Edit") {
                    const docMisi = firestore.doc(`misi/${data.uid}`);
                    const { judul, reward, status, url, order } = form;

                    await docMisi.set({
                        updatedAt: Arr.serverTimestamp(),
                        judul,
                        reward,
                        url,
                        order: parseInt(order),
                        status_misi: status
                    }, { merge: true })

                    enqueueSnackbar('Misi Berhasil Diperbarui', { variant: "success" })
                } else {
                    const docMisi = firestore.collection('misi');
                    const { judul, reward, status, url, order } = form;

                    await docMisi.add({
                        updatedAt: Arr.serverTimestamp(),
                        createdAt: Arr.serverTimestamp(),
                        judul,
                        reward,
                        url,
                        status_misi: status,
                        content: '',
                        order: parseInt(order),
                        kategori: 'misi',
                    })

                    enqueueSnackbar('Misi Berhasil Dibuat', { variant: "success" })
                }
                handleClose()
            } catch (e) {
                console.log(e.message)
            }

            setSubmitting(false)
        }
    }

    let title = "Buat Misi Baru";
    let btn = "Buat";

    if (mode === 'Edit') {
        title = "Edit Misi";
        btn = "Perbarui";
    }

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <form id="create-admin-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting}
                                autoComplete="off"
                                id="judul"
                                name="judul"
                                label="Judul Misi"
                                fullWidth
                                variant="outlined"
                                value={form.judul}
                                onChange={handleChange}
                                helperText={error.judul}
                                error={error.judul ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting}
                                autoComplete="off"
                                id="reward"
                                name="reward"
                                InputProps={{
                                    inputComponent: Numberformat
                                }}
                                label="Reward"
                                fullWidth
                                variant="outlined"
                                value={form.reward}
                                onChange={handleChange}
                                helperText={error.reward}
                                error={error.reward ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting}
                                autoComplete="off"
                                id="order"
                                name="order"
                                InputProps={{
                                    inputComponent: Numberformat
                                }}
                                label="Urutan Misi"
                                fullWidth
                                variant="outlined"
                                value={form.order}
                                onChange={handleChange}
                                helperText={error.order}
                                error={error.order ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={isSubmitting}
                                autoComplete="off"
                                id="url"
                                name="url"
                                label="URL Misi"
                                fullWidth
                                variant="outlined"
                                placeholder='https://instagram.com/xxxx'
                                value={form.url}
                                onChange={handleChange}
                                helperText={error.url}
                                error={error.url ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth disabled={isSubmitting} variant="outlined">
                                <InputLabel htmlFor="native-simple">Status Misi</InputLabel>
                                <Select
                                    native
                                    value={form.status}
                                    onChange={handleChange}
                                    label="Status Misi"
                                    inputProps={{
                                        name: 'status',
                                        id: 'native-simple',
                                    }}
                                >
                                    <option value={"active"}>Aktif</option>
                                    <option value={"non-active"}>Tidak Aktif</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    form="create-admin-form"
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                >
                    {btn}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddDialogN;
