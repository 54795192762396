import React, { useEffect, useState } from 'react';

import { firestore } from '../../../components/FirebaseProvider';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';

// Styles
// import useStyles from './styles';

import MaterialTable from '@material-table/core';
import AppPageLoading from '../../../components/AppPageLoading';
import Papa from "papaparse";
import { saveAs } from "file-saver";
import Paper from '@material-ui/core/Paper';
// import Tooltip from '@material-ui/core/Tooltip'
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DialogPeserta from './dialogPeserta';
// import Fab from '@material-ui/core/Fab';
// import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
// import { currency, delimiters } from '../../../utils/formatter-rupiah';
import { useParams } from 'react-router-dom';
import { unixToDate } from '../../../utils/formatter';
import getTableTitle from '../../../utils/getTableTitle';

function Peserta() {
    const params = useParams();
    // const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const queryPromo = firestore.collection(`events/${params.eventId}/peserta_event`);
    const [snapshot, loading] = useCollection(queryPromo);
    const [event, loadingEvent] = useDocumentData(firestore.doc(`events/${params.eventId}`), { idField: 'uid' })

    const [dialog, setDialog] = useState({
        open: false,
        mode: '',
        data: []
    })

    const [table, setTable] = useState({
        columns: [
            {
                title: getTableTitle("Nama"),
                field: "nama_lengkap",
            },
            {
                title: getTableTitle("Email"),
                field: "email",
            },
            {
                title: getTableTitle('No Whatsapp Aktif'), field: 'no_wa',
                render: rowData =>
                    <>
                        {
                            <a target="_blank" rel="noopener noreferrer" href={`https://wa.me/${rowData.no_wa}`}>{rowData.no_wa}</a>
                        }
                    </>
            }
        ],
        data: [
        ],
    });

    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            setTable(table => {
                return {
                    ...table,
                    data: snapshot.docs.map(doc => {
                        return {
                            uid: doc.id,
                            ...doc.data()
                        }
                    })
                }
            })
        } else {
            setTable(table => {
                return {
                    ...table,
                    data: []
                }
            })
        }
    }, [snapshot]);

    if (loading || loadingEvent) {
        return <AppPageLoading />
    }

    return (
        <>
            <Paper square>
                <MaterialTable
                    options={{ thirdSortClick: false }}
                    options={{
                        exportButton: true,
                        exportCsv: (columns, data) => {
                            // console.log(columns, data)
                            // alert('You should develop a code to export ' + data.length + ' rows');
                            const csvData = Papa.unparse(
                                data.map(to => {

                                    return {
                                        "ID Peserta": to.pesertaId,
                                        "Nama Lengkap": to.nama_lengkap,
                                        "No Whatsaap": to.no_wa,
                                        "No Telepon": to.no_telepon,
                                        "Email Pendaftaran": to.email,
                                        "Lokasi Event ID": to.lokasi_event?.id,
                                        "Lokasi Event": to.lokasi_event?.name,
                                        "Jenis Tryout": to.jenis_tryout,
                                        "Transaksi ID": to.transaksi_event_id,
                                        "PakBudi Tryout ID": to.pakbdi_tryout_id,
                                        "Dibuat Pada": unixToDate(to.created_at.toMillis()),
                                        //"Diperbarui Pada": unixToDate(to.updated_at.toMillis()),
                                        "Asal provinsi": to.provinsi?.nama,
                                        "Asal Kota": to.kota?.nama,
                                        "Asal Sekolah": to.asal_sekolah ? to.asal_sekolah : "",
                                        "Kelas": to.kelas?.nama,
                                        "Tahu dari": to.tahu ? to.tahu : "",
                                        "Referensi Nama Teman": to.telpon_teman ? to.telpon_teman : "",
                                        "Referensi Telp Teman": to.teman ? to.teman : ""
                                    };
                                })
                            );
                            const csvBlob = new Blob([csvData], { type: "text/plain;charset=utf-8" });
                            saveAs(csvBlob, `Peserta-Event-${event.nama}-${event.uid}.csv`);
                        }

                    }
                    }
                    title={`Peserta Event ${event.nama}`}
                    columns={table.columns}
                    data={table.data}
                    actions={[
                        {
                            icon: 'visibility',
                            tooltip: 'View',
                            onClick: (event, data) => {
                                setDialog({
                                    mode: 'View',
                                    data,
                                    open: true
                                })
                            }
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, data) => {
                                setDialog({
                                    mode: 'Edit',
                                    data,
                                    open: true
                                })
                            }
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Hapus',
                            onClick: (event, data) => {
                                queryPromo.doc(data.uid).delete()
                                enqueueSnackbar('Peserta Berhasil Dihapus', { variant: "success" })
                            }
                        },
                    ]}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Tidak Ada Data',
                            addTooltip: 'Tambah',
                            editTooltip: 'Ubah',
                            deleteTooltip: 'Hapus',
                            editRow: {
                                deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                                cancelTooltip: 'Batal',
                                saveTooltip: 'Ya'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Cari',
                            searchPlaceholder: 'Cari Data'
                        },
                        header: {
                            actions: 'Tindakan'
                        },
                        pagination: {
                            labelRowsSelect: 'Baris',
                            labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
                            firstTooltip: 'Halaman Pertama',
                            previousTooltip: 'Halaman Sebelumnya',
                            nextTooltip: 'Halaman Selanjutnya',
                            lastTooltip: 'Halaman Terakhir'
                        }
                    }}
                />
                {/* <Fab
                    className={classes.fab}
                    color="primary"
                    onClick={() => {
                        setDialog({ mode: 'Tambah', open: true, data: {} });
                    }}
                >
                    <AddOutlinedIcon />
                </Fab> */}
                <DialogPeserta
                    dialog={dialog}
                    handleClose={() => {
                        setDialog({ mode: 'Tambah', open: false, data: {} });
                    }}
                    pesertaRef={queryPromo}
                />
            </Paper>
        </>
    )
}

export default Peserta;