// React
import React, { useEffect, useState } from 'react';

// Router
import { useParams, useHistory } from 'react-router-dom';

// Utils
import { firestore } from '../../../components/FirebaseProvider';
import { useCollection } from 'react-firebase-hooks/firestore';
import { formatDate, unixToDate, formatDate2 } from '../../../utils/formatter';
import { useSnackbar } from 'notistack';
import Papa from "papaparse";
import { saveAs } from "file-saver";

// Styles
import useStyles from './styles';

// Icon's
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import ArchiveIcon from '@material-ui/icons/Archive';
import ScheduleIcon from '@material-ui/icons/Schedule';

// Component's
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import MaterialTable from '@material-table/core';
import AppPageLoading from '../../../components/AppPageLoading';
import AddDialogC from './addCategory';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddDialogN from './addTryout';
import DownloadDialog from './DialogDownload';
import getTableTitle from '../../../utils/getTableTitle';

function Tabel() {
    // Router
    const params = useParams();
    const history = useHistory();
    // Style's
    const classes = useStyles();
    // Snackbar
    const { enqueueSnackbar } = useSnackbar();
    // Filter
    const [filter, setFilter] = useState({
        id: '',
        open: false,
        isLoad: false
    })
    // Columns
    const columns = {

        kategori: [
            {
                title: getTableTitle('Id'), field: 'id',
                render: rowData =>
                    <span>{rowData.tableData.id + 1}</span>
            },
            { title: getTableTitle('Judul'), field: 'title' },
            {
                title: getTableTitle('Dibuat Pada'), field: 'createdAt',
                render: rowData =>
                    <span>{formatDate(rowData.createdAt)}</span>
            },
            {
                title: getTableTitle('Diperbarui Pada'), field: 'updatedAt',
                render: rowData =>
                    <span>{formatDate(rowData.updatedAt)}</span>
            },
        ],

        published: [
            { title: getTableTitle('Judul'), field: 'judul' },
            { title: getTableTitle('Coin'), field: 'coin' },
            {
                title: getTableTitle('Waktu(Menit)'), field: 'waktu',
                render: rowData =>
                    <span>{rowData.waktu / 60}</span>
            },
            {
                title: getTableTitle('Soal Random'), field: 'random',
                render: rowData =>
                    <>
                        {
                            rowData.random === true ?
                                <span>Ya</span>
                                :
                                <span>Tidak</span>

                        }
                    </>
            },
            { title: getTableTitle('Urutan Di Home'), defaultSort: 'asc', field: 'order' },
            {
                title: getTableTitle('Status'), field: 'status',
                render: rowData =>
                    <>
                        {
                            rowData.status === "published" ?
                                <Tooltip title="Tryout Aktif" arrow>
                                    <WifiTetheringIcon style={{ color: '#34cd2b' }} />
                                </Tooltip>
                                :
                                rowData.status === "coming soon" ?
                                    <Tooltip title="Tryout Segera Rilis" arrow>
                                        <ScheduleIcon color="primary" />
                                    </Tooltip>
                                    :
                                    <Tooltip title="Tryout Tidak Aktif" arrow>
                                        <WifiTetheringIcon color="error" />
                                    </Tooltip>

                        }
                    </>
            },
            {
                title: 'Download Hasil',
                sorting: false,
                render: rowData =>
                    <div
                        style={{
                            display: 'flex',
                            flexGrow: 1,
                            width: 90,
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setFilter({
                                    id: rowData.uid,
                                    open: true
                                })
                            }}
                        >
                            <ArchiveIcon />
                        </IconButton>
                    </div>
            },
        ]
    }
    // Query TO
    const queryTryout = params.status === 'kategori' ? firestore.collection('kategori_soal') : firestore.collection('tryout');

    const [snapshot, loading] = useCollection(queryTryout);
    // State
    const [table, setTable] = useState({
        columns: params.status === 'kategori' ? columns.kategori : columns.published,
        data: [],
    });

    const [hasilTo, setHasilTo] = useState([]);

    const [kategoriDialog, setKategoriDialog] = useState({
        open: false,
        mode: 'Tambah',
        data: {}
    });

    const [openAddDialogN, setOpenAddDialogN] = useState({
        open: false,
        mode: 'Tambah',
        data: {}
    });
    // Download Data
    const handleDownloadData = () => {
        const csvData = Papa.unparse(
            hasilTo.map(to => {
                const hasil = Object.entries(to.hasil).map(([key, item]) => {
                    return {
                        [`${item.nama}-Benar`]: item.benar,
                        [`${item.nama}-Salah`]: item.salah,
                    }
                })
                const item = Object.assign(hasil);
                const result = JSON.stringify(item).replace(/[.?*+^$[\]\\(){}|-|""]/g, '');
                return {
                    "Hasil Tryout ID": to.id,
                    Nama:
                        to.siswa && to.siswa.nama ? to.siswa.nama : "Tidak Ada Data",
                    Email:
                        to.siswa && to.siswa.email ? to.siswa.email : "Tidak Ada Data",
                    NomorHP:
                        to.siswa && to.siswa.no_hp && to.siswa.no_hp !== "" ? to.siswa.no_hp : "Tidak Ada Data",
                    "Judul Tryout":
                        to.tryout && to.tryout.nama && to.tryout.nama !== ""
                            ? to.tryout.nama
                            : "Tidak Ada Data",
                    "Waktu Pembelian": unixToDate(to.created_at.toMillis()),
                    "Status TO": to.status,
                    "Waktu Mulai": to.started_at ? formatDate2(to.started_at) : "Tidak Ada Data",
                    "Waktu Berakhir": to.ended_at ? formatDate2(to.ended_at) : "Tidak Ada Data",
                    "Hasil": result ? result : 'Tidak Ada Data'
                };
            })
        );
        const csvBlob = new Blob([csvData], { type: "text/plain;charset=utf-8" });
        saveAs(csvBlob, `Hasil-${hasilTo.length > 0 ? `${hasilTo[0].tryout.nama}-PakBudi` : 'PakBudi'}.csv`);
        setFilter({
            id: '',
            open: false,
            isLoad: false
        });
        setTimeout(() => {
            enqueueSnackbar("Mengunduh Data...", { variant: "info" });
        }, 500);
    };
    // UseEffect Download
    useEffect(() => {
        console.log('here effect')
        if (filter.id) {
            let lastVisible;
            setFilter(filter => ({
                ...filter,
                isLoad: true
            }))
            setHasilTo([]);
            async function getHasilTo() {
                const hasilToColRef = firestore.collection('hasil_tryout')

                let hasilToSnapshots;
                if (lastVisible) {
                    console.log('here next')
                    hasilToSnapshots = await hasilToColRef.where('tryout.id', '==', filter.id).orderBy('siswa_id').startAfter(lastVisible).limit(250).get();
                } else {
                    console.log('here first')
                    hasilToSnapshots = await hasilToColRef.where('tryout.id', '==', filter.id).orderBy('siswa_id').limit(250).get();
                }

                if (!hasilToSnapshots.empty) {
                    const dataHasilTo = hasilToSnapshots.docs.map((doc) => {

                        return {
                            id: doc.id,
                            ...doc.data()
                        }
                    })
                    setHasilTo(items => ([...items, ...dataHasilTo]));
                    lastVisible = hasilToSnapshots.docs[hasilToSnapshots.docs.length - 1];
                    // here the loop
                    await getHasilTo()
                } else {
                    setFilter(filter => ({
                        ...filter,
                        isLoad: false
                    }));
                    // here the loop end
                    return;
                }
            }

            getHasilTo();

        }
    }, [filter.id])


    // UseEffect
    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            setTable(table => {
                return {
                    ...table,
                    columns: params.status === 'kategori' ? columns.kategori : columns.published,
                    data: snapshot.docs.map(doc => {
                        return {
                            uid: doc.id,
                            ...doc.data()
                        }
                    })
                }
            })
        } else {
            setTable(table => {
                return {
                    ...table,
                    columns: params.status === 'kategori' ? columns.kategori : columns.published,
                    data: []
                }
            })
        }
    }, [params.status, snapshot]);

    // Conditional
    if (loading) {
        return <AppPageLoading />
    }
    // Title
    const title = params.status === "kategori" ? "Kategori Soal" : "Tryout List";
    // Render
    return <React.Fragment>

        <MaterialTable
            options={{ thirdSortClick: false }}
            title={title}
            columns={table.columns}
            data={table.data}
            actions={
                [
                    {
                        icon: 'edit',
                        tooltip: 'Edit Tryout',
                        onClick: (event, data) => {
                            if (params.status === 'kategori') {
                                setKategoriDialog({
                                    open: true,
                                    mode: 'Edit',
                                    data
                                });
                            } else if (params.status === 'published') {
                                setOpenAddDialogN({
                                    mode: 'Edit',
                                    open: true,
                                    data
                                })
                            }
                        }
                    },
                    {
                        icon: 'add',
                        tooltip: 'Tambah Soal',
                        hidden: params.status !== "published",
                        onClick: (event, data) => {
                            history.push(`/tryout/tambah/${data.uid}`);
                        }
                    },
                ]
            }
            editable={
                {
                    onRowDelete: async (oldData) => {
                        try {
                            if (params.status === 'kategori') {
                                await firestore.doc(`kategori_soal/${oldData.uid}`).delete()
                            } else if (params.status === 'published') {
                                await firestore.doc(`tryout/${oldData.uid}`).delete()
                            }
                            enqueueSnackbar('Data Berhasil Dihapus', { variant: "success" })
                        } catch (e) {
                            console.log(e.message)
                        }
                    }
                }
            }
            localization={{
                body: {
                    emptyDataSourceMessage: title + ' Tidak Ada',
                    addTooltip: 'Tambah',
                    editTooltip: 'Ubah',
                    deleteTooltip: 'Hapus',
                    editRow: {
                        deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
                        cancelTooltip: 'Batal',
                        saveTooltip: 'Ya'
                    }
                },
                toolbar: {
                    searchTooltip: 'Cari',
                    searchPlaceholder: 'Cari Data'
                },
                header: {
                    actions: 'Tindakan'
                },
                pagination: {
                    labelRowsSelect: 'Baris',
                    labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
                    firstTooltip: 'Halaman Pertama',
                    previousTooltip: 'Halaman Sebelumnya',
                    nextTooltip: 'Halaman Selanjutnya',
                    lastTooltip: 'Halaman Terakhir'
                }
            }}
        />

        {
            params.status === "kategori" &&
            <>
                <Fab
                    className={classes.fab2}
                    color="primary"
                    onClick={() => {
                        setKategoriDialog({
                            mode: 'Tambah',
                            open: true,
                            data: {}
                        })
                    }}
                >
                    <CategoryOutlinedIcon />
                </Fab>

                <AddDialogC
                    open={kategoriDialog.open}
                    handleClose={() => {
                        setKategoriDialog({
                            open: false,
                            data: {}
                        })
                    }}
                    mode={kategoriDialog.mode}
                    data={kategoriDialog.data}
                />
            </>
        }

        {
            params.status === "published" &&
            <>
                <Fab
                    className={classes.fab1}
                    color="primary"
                    onClick={() => {
                        setOpenAddDialogN({
                            open: true,
                            mode: 'Tambah',
                        });
                    }}
                    tooltip
                >
                    <PostAddIcon />
                </Fab>
                <AddDialogN
                    open={openAddDialogN.open}
                    mode={openAddDialogN.mode}
                    data={openAddDialogN.data}
                    handleClose={() => {
                        setOpenAddDialogN({
                            open: false,
                        });
                    }}
                />
            </>
        }

        <DownloadDialog
            {...filter}
            handleClose={() => {
                setFilter({
                    id: '',
                    open: false,
                    isLoad: false
                })
            }}
            handleDownloadData={handleDownloadData}
        />
    </React.Fragment>
}

export default Tabel;