import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
    
    dataCon: {
        textAlign: 'center',
        backgroundColor: '#1abc9c',
        color: 'white',
        borderRadius: 5,
        marginBottom: 50,
    },

    cardText: {
        fontWeight: 400,
        fontSize: 11,
        marginBottom: 35,
        color: '#5e5e5e',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },

    pageHeader: {
        marginTop: 40,
        marginBottom: 15,
    },

    mainLogo: {
        marginTop: -10
    },

    pageTitle: {
        color: '#5e5e5e',
        marginBottom: -15,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },

    cardColor1: {
        // background: 'linear-gradient(to right, #3096ec, #30bcec)',
        backgroundColor: "#25324B",
        color: 'white',
        borderRadius: 8,
        boxShadow: "none",
    },  

    '& .recharts-legend-item-text' : {
        color: "#333 !important"
    },

    cardColor2: {
        background: 'linear-gradient(to right, #ff4e50, #f9d423)',
        // opacity: 0.5,
        color: 'white',
        cursor: 'context-menu',
    },

    cardC: {
        marginTop: 5,
    },

    cardI: {
        padding: 10,
    },

    text1a: {
        fontSize: 18,
        textAlign: 'left',
        fontWeight: 'bold',
    },

    text1b: {
        fontSize: 12,
        textAlign: 'left',
        marginBottom: 9
    },

    text1c: {
        fontSize: 25,
        textAlign: 'left',
        fontWeight: 600,
    },

    link: {
        color: 'white',
        textDecoration: 'none'
    },

    btn: {
        color: 'white',
        border: '1px solid white',
        marginTop: 20,
    },


}));

export default useStyles;