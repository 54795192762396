// React
import React, { useCallback, useState } from 'react';

// Utils
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { functions } from '../../../components/FirebaseProvider';
// import { useParams } from 'react-router-dom';
// import { useDocument } from 'react-firebase-hooks/firestore';

// Styles
// import useStyles from './styles';

// MUI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import PhotoIcon from '@material-ui/icons/Photo';


function UploadFoto({mode, setData, pilihan, setErr, Err}) {
    // const classes = useStyles();

    // const params = useParams();

    const [jawaban, setJawaban] = useState('');

    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false);

    const accept = ['image/png', 'image/jpeg'];

    const maxSize = '5242880';

    // Snackbar
    const { enqueueSnackbar } = useSnackbar();

    const handleMode = (jawab) => {
        setJawaban(jawab)
    }

    const onDropAccepted = useCallback(acceptedFiles => {
        setError();
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onabort = () => {
            setError('Pembacaan File Dibatalkan')
        }
        reader.onerror = () => {
            setError('Pembacaan File Gagal')
        }
        reader.onload = (ev) => {
            setLoading(true);
            try {


                const img = new Image();

                img.src = ev.target.result;

                img.onload = () => {
                    const elem = document.createElement('canvas');
                    const width = 650;
                    const ratio = img.width / img.height;

                    elem.width = width;
                    const height = width / ratio;
                    elem.height = height;

                    const ctx = elem.getContext('2d');

                    ctx.drawImage(img, 0, 0, width, height);

                    ctx.canvas.toBlob(
                        async blob => {
                            const compressedfile = new File([blob], file.name, {
                                type: file.type,
                                lastModified: Date.now()
                            })

                            //console.log(compressedfile)
                            const generateUploadUrl = functions.httpsCallable('upload-generateUploadUrl');

                            const result = await generateUploadUrl({
                                filename: file.name,
                                directory: `images/tryout/`,
                                contentType: file.type,
                            })

                            await axios.put(result.data.uploadUrl, compressedfile, {
                                headers: {
                                    'Content-Type': file.type
                                }
                            })

                            setData({
                                ...pilihan,
                                [jawaban]: {
                                    url: result.data.photoUrl,
                                    text: '',
                                    type: 'image'
                                }
                            })

                            setErr({
                                ...Err,
                                [jawaban]: ''
                            })

                            // Show Notification
                            enqueueSnackbar('Gambar Berhasil Diupload', { variant: 'success' });
                            setLoading(false)
                        },
                        file.type,
                        0.8
                    )
                }


            } catch (err) {
                setError(err.message);
                setLoading(false);

            }
        }

        reader.readAsDataURL(file);

    }, [Err, enqueueSnackbar, jawaban, pilihan, setData, setErr])

    const onDropRejected = useCallback(rejected => {
        if (!accept.includes(rejected[0].type)) {
            setError(`Tipe Tile Tidak Didukung (${rejected[0].type}) `)
        }
        else if (rejected[0].size >= maxSize) {
            setError(`Ukuran File Terlalu Besar > 5MB`)
        }
    }, [accept, maxSize])

    const { getRootProps, getInputProps } = useDropzone({ onDropAccepted, onDropRejected, accept, maxSize, disabled: loading })
    return (
        <>
            <div {...getRootProps()}>
                <div>
                    <Grid container justify="center" spacing={1}>
                        {/* Button */}
                        <Grid item>
                            <input {...getInputProps()} />
                            <label htmlFor="uplod">
                                <IconButton disabled={loading} color="primary" onClick={() => handleMode(mode)}>
                                    <PhotoIcon />
                                </IconButton>
                            </label>
                        </Grid>
                        {
                            error &&
                            <Typography
                                color="error"
                                variant="caption"
                                align="center"
                            >
                                {error}
                            </Typography>
                        }
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default UploadFoto;
