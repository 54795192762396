// React
import React, { useCallback,useState, useEffect } from 'react';

// Utils
import { useDropzone } from 'react-dropzone'
import {useSnackbar} from 'notistack';
import axios from 'axios';
import { firestore, functions } from '../../../components/FirebaseProvider';
import { useParams } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';

// Styles
import useStyles from './styles';

// MUI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';


function UploadFoto() {
    const classes = useStyles();

    const params = useParams();

    const counterDoc = firestore.doc(`news/${params.newsId}`)
    
    const [snapshot] = useDocument(counterDoc);

    const [Thumb, setThumb] = useState({
        thumbnailURL: ''
    });
    
    useEffect(() => {
        if(snapshot){
            setThumb(snapshot.data());
        }
    }, [snapshot])


    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false);

    const accept = ['image/png', 'image/jpeg'];

    const maxSize = '5242880';

    // Snackbar
    const {enqueueSnackbar} = useSnackbar();

    const onDropAccepted = useCallback(acceptedFiles => {
        setError();
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onabort = () => {
            setError('Pembacaan File Dibatalkan')
        }
        reader.onerror = () => {
            setError('Pembacaan File Gagal')
        }
        reader.onload =  (ev) => {
            setLoading(true);
            try {
               

                const img = new Image();

                img.src = ev.target.result;

                img.onload = () => {
                    const elem = document.createElement('canvas');
                    const width = 650;
                    const ratio = img.width / img.height;

                    elem.width = width;
                    const height = width / ratio;
                    elem.height = height;

                    const ctx = elem.getContext('2d');

                    ctx.drawImage(img, 0, 0, width, height);

                    ctx.canvas.toBlob(
                       async blob => {
                            const compressedfile = new File([blob], file.name, {
                                type: file.type,
                                lastModified: Date.now()
                            })

                            //console.log(compressedfile)
                            const generateUploadUrl = functions.httpsCallable('upload-generateUploadUrl');

                            const result = await generateUploadUrl({
                                filename: file.name,
                                directory: `images/news/`,
                                contentType: file.type,
                            })

                            await axios.put(result.data.uploadUrl, compressedfile, {
                                headers: {
                                    'Content-Type': file.type
                                }
                            })
            
            
                            // await user.updateProfile({
                            //     photoURL: result.data.photoUrl,
                            // })

                        await firestore.doc(`news/${params.newsId}`).set({
                                thumbnailURL: result.data.photoUrl,
                            }, {merge : true});

                            // Show Notification
                            enqueueSnackbar('Thumbnail Berhasil Diupload',{variant: 'success'});
                            setLoading(false)
                        },
                        file.type, 
                        0.8
                    )
                }


            } catch (err) {
                setError(err.message);
                setLoading(false);

            }
        }

        reader.readAsDataURL(file);

    }, [enqueueSnackbar, params.newsId])

    const onDropRejected = useCallback(rejected => {
        if (!accept.includes(rejected[0].type)) {
            setError(`Tipe Tile Tidak Didukung (${rejected[0].type}) `)
        }
        else if (rejected[0].size >= maxSize) {
            setError(`Ukuran File Terlalu Besar > 5MB`)
        }
    }, [accept, maxSize])

    const { getRootProps, getInputProps } = useDropzone({ onDropAccepted, onDropRejected, accept, maxSize, disabled: loading })
    return (
        <>
            <Card {...getRootProps()} className={classes.avatarChange}>
                        <div>
                            <Grid container justify="center" spacing={1} className={classes.thumbLayout}>
                                {/* Button */}
                                <Grid item xs={12} className={classes.thumbMB}>
                                    <Typography variant="body1" align="left" className={classes.thumbMB}> Thumbnail Berita </Typography>
                                    <input {...getInputProps()} />
                                    <label htmlFor="uplod">
                                        <Button fullWidth size="large" variant="contained" color="primary" component="span">
                                            Pilih File
                                        </Button>
                                    </label>
                                </Grid>
                                {/* Preview */}
                                {   Thumb.thumbnailURL ?
                                    <Grid item xs={12} className={classes.thumbPLayout}>
                                        <img className={classes.thumbPreview} src={Thumb.thumbnailURL} alt=""/>
                                    </Grid>
                                    :
                                    <Typography style={{margin: 30}} variant="caption" color="secondary">Belum Ada Gambar. Gambar Akan Tampil Disini Jika Tersedia</Typography>
                                }
                                {
                                    error &&
                                        <Typography
                                            color="error"
                                            variant="caption"
                                            align="center"
                                        >
                                            {error}
                                        </Typography>
                                }
                            </Grid>
                        </div>
            </Card>
        </>
    )
}

export default UploadFoto;
